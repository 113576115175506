import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import styles from '../../styles/slices/ThreeColumnCaseStudyFeed.module.scss';
import classnames from 'classnames';
import Link from 'next/link';
import CaseStudyCard from '../../components/caseStudyCard/caseStudyCard';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

const ThreeColumnCaseStudyFeed = (
  { slice, cards, type, casestudies, cardlink, tags },
  ref
) => {
  const [cardArray, setCardArray] = useState([]);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);

  const sortCards = (cardArr) => {
    cardArr.map((item) => {
      if (!item.data.sortingdate) {
        item.data.sortingdate = new Date(item.last_publication_date);
      } else {
        item.data.sortingdate = new Date(item.data.sortingdate);
      }
    });
    const sortedCards = cardArr.sort(function (a, b) {
      return b.data.sortingdate - a.data.sortingdate;
    });
    return sortedCards;
  };

  useEffect(() => {
    if (!type) {
      const cardArr = structuredClone(cards);
      setCardArray(sortCards(sortedCards));
    } else if (type === 'related') {
      let tempCaseStudies = [];

      tags.map((tag) => {
        const temp = casestudies.results.filter((result) =>
          result.tags.includes(tag)
        );
        tempCaseStudies = tempCaseStudies.concat(temp);
      });

      const unique = [
        ...new Set(tempCaseStudies.map((item) => item.data.cardlink.id)),
      ];
      if (unique.includes(cardlink.id)) {
        unique.splice(unique.indexOf(cardlink.id), 1);
      }

      tempCaseStudies = [];
      cards.map((card) => {
        if (unique.includes(card.id)) {
          tempCaseStudies.push(card);
        }
      });

      if (tempCaseStudies.length < 4) {
        const sortedCards = structuredClone(cards);
        const additionalCards = sortCards(sortedCards).filter(
          (item) => !unique.includes(item.id)
        );
        const lgt = tempCaseStudies.length;
        for (let i = 0; i < 4 - lgt; i++) {
          tempCaseStudies.push(additionalCards[i]);
        }
      }

      setCardArray(sortCards(tempCaseStudies));
    }
  }, [cards, type, casestudies, cardlink, tags]);

  return (
    <section
      className={classnames(
        styles.threeColumnCaseStudyFeedWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      ref={ref}
      id={slice.primary.uid || ''}
    >
      <div className={styles.textWrapper} ref={wrapperRef}>
        <span className={styles.subtitle}>
          {slice.primary.subtitle ? (
            <PrismicRichText field={slice.primary.subtitle} />
          ) : null}
        </span>
        <span className={styles.title}>
          {slice.primary.title ? (
            <PrismicRichText field={slice.primary.title} />
          ) : null}
        </span>
      </div>
      <div className={styles.btnWrapper}>
        {slice.primary.buttonlink.url ? (
          <Link
            href={slice.primary.buttonlink.url}
            target={slice.primary.buttonlink.target}
            className={classnames(styles.btn, styles.primary_red)}
          >
            {slice.primary.buttontext}
          </Link>
        ) : null}
      </div>
      <div className={styles.article_wrapper}>
        {cardArray.slice(0, 3).map((item, index) => {
          item.layout = 'expanded';
          return <CaseStudyCard key={index} data={item} />;
        })}
      </div>
    </section>
  );
};

export default forwardRef(ThreeColumnCaseStudyFeed);
