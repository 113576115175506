import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useRef,
} from 'react';
import { PrismicRichText } from '@prismicio/react';
import Link from 'next/link';
import styles from '../../styles/slices/FourColumnCardFeed.module.scss';
import { MobileContext } from '../../context/mobileContext';
import classnames from 'classnames';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import CaseStudyCard from '../../components/caseStudyCard/caseStudyCard';

const FourColumnCardFeed = (
  { slice, cards, type, articles, cardlink, tags },
  ref
) => {
  const [cardVisible, setCardVisible] = useState([]);
  const isMobile = useContext(MobileContext);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);

  const sortCards = (cardArr) => {
    const tempFilteredCards = cardArr.filter(
      (item) => item.data.hidden == false
    );
    tempFilteredCards.map((item) => {
      if (!item.data.sortingdate) {
        item.data.sortingdate = new Date(item.last_publication_date);
      } else {
        item.data.sortingdate = new Date(item.data.sortingdate);
      }
    });
    const sortedDatesSticky = tempFilteredCards
      .filter((val) => val.data.sticky == true)
      .sort(function (a, b) {
        return b.data.sortingdate - a.data.sortingdate;
      });
    const sortedDatesNo = tempFilteredCards
      .filter((val) => val.data.sticky == false)
      .sort(function (a, b) {
        return b.data.sortingdate - a.data.sortingdate;
      });

    const newArray = sortedDatesSticky.concat(sortedDatesNo);
    return newArray;
  };

  useEffect(() => {
    if (!type) {
      const sortedCards = structuredClone(cards);
      setCardVisible(sortCards(sortedCards));
    } else if (type === 'related') {
      let tempArticles = [];

      tags.map((tag) => {
        const temp = articles.results.filter((result) =>
          result.tags.includes(tag)
        );
        tempArticles = tempArticles.concat(temp);
      });

      const unique = [
        ...new Set(tempArticles.map((item) => item.data.cardlink.id)),
      ];
      if (unique.includes(cardlink.id)) {
        unique.splice(unique.indexOf(cardlink.id), 1);
      }

      tempArticles = [];
      cards.map((card) => {
        if (unique.includes(card.id)) {
          tempArticles.push(card);
        }
      });

      if (tempArticles.length < 4) {
        const sortedCards = structuredClone(cards);
        const additionalCards = sortCards(sortedCards).filter(
          (item) => !unique.includes(item.id)
        );
        const lgt = tempArticles.length;
        for (let i = 0; i < 4 - lgt; i++) {
          tempArticles.push(additionalCards[i]);
        }
      }

      setCardVisible(sortCards(tempArticles));
    }
  }, [cards, type, articles, cardlink, tags]);

  return (
    <section
      className={styles.fourColumn_section}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div
        className={classnames(
          styles.fourColumn_wrapper,
          onScreen?.isIntersecting ? styles.visible : ''
        )}
        ref={wrapperRef}
      >
        <div className={styles.textContent}>
          {/* <div className={styles.subtitle}>
            {slice.primary.subtitle ? (
              <PrismicRichText field={slice.primary.subtitle} />
            ) : null}
          </div> */}
          <div className={styles.title_wrapper}>
            <span className={styles.title}>
              {slice.primary.title ? (
                <PrismicRichText field={slice.primary.title} />
              ) : null}
            </span>
            {!isMobile ? (
              <Link
                href={slice.primary.buttonurl.url}
                target={slice.primary.buttonurl.target}
                className={classnames(styles.btn, styles.primary_red)}
              >
                {slice.primary.buttontext}
              </Link>
            ) : undefined}
          </div>
        </div>
        <div className={styles.article_wrapper}>
          {cardVisible.slice(0, 3).map((item, index) => {
            return <CaseStudyCard variant='slide' key={index} data={{
              ...item,
              layout: 'newsroom'
            }} />;
          })}
        </div>
        {isMobile ? (
          <Link
            href={slice.primary.buttonurl.url}
            target={slice.primary.buttonurl.target}
            className={classnames(styles.btn, styles.primary_red)}
          >
            {slice.primary.buttontext}
          </Link>
        ) : undefined}
      </div>
    </section>
  );
};

export default forwardRef(FourColumnCardFeed);
