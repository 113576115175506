import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';
import styles from '../../styles/slices/FooterLegalMenu.module.scss';
import Link from 'next/link';

const FooterLegalMenu = ({ slice }) => {
  return (
    <section>
      <div className={styles.legal_menu}>
        <ul className={styles.legal_list}>
          {slice.items.map((item, index) => (
            <li key={index}>
              <Link
                href={item.legallinkcta.url}
                passHref
                target={item.legallinkcta.target}
              >
                {item.legallinktext}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default FooterLegalMenu;
