import { useCallback, useEffect, useState } from 'react';

const useSetupVideo = () => {
  function isBrowser() {
    return typeof window !== 'undefined';
  }

  const getDimensions = useCallback(() => {
    if (!isBrowser()) return { width: 0, height: 0 };

    const viewportWidth = window.innerWidth;
    const width = viewportWidth >= 768 ? viewportWidth * 0.75 : viewportWidth;

    return {
      width,
      height: window.innerHeight,
    };
  }, []);

  const [dimensions, setDimensions] = useState(getDimensions());

  useEffect(() => {
    if (!isBrowser()) return;

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getDimensions]);

  return {
    dimensions
  }
};

export default useSetupVideo;
