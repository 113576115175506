import styles from '../../styles/components/CaseStudyCard.module.scss';
import React, { useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import Link from 'next/link';
import Image from 'next/image';
import classnames from 'classnames';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import classNames from 'classnames';
import capitalizeString from '../../utils/capitalizeString';

export default function CaseStudyCard({ data, variant='grid' }) {
  console.log('data.data: ', data.data);
  const isNewsroomLayout = data.layout && data.layout === 'newsroom';
  const urlData = isNewsroomLayout
    ? {
        ...data.data.articlepageurl,
        buttonlinktext: data.data.articlepagelinktext ?? 'Read More',
      }
    : {
        ...data.data,
      };
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);

  const isMedia = data.data.ismedia;
  const linkUrl = isNewsroomLayout
    ? urlData.url
    : isMedia
    ? urlData.mediabuttonlinkurl.url
    : urlData.buttonlinkurl.url;

  const linkTarget = isNewsroomLayout
    ? urlData.target
    : isMedia
    ? urlData.mediabuttonlinkurl.target
    : urlData.buttonlinkurl.target;

  const buttonName = isMedia ? 'media link' : 'case study link';

  const bodyWrapper = (
    <div
      className={classNames(
        styles.article_body_wrapper,
        variant === 'slide'
          ? styles.slideBodyVariantion
          : styles.gridBodyVariation
      )}
    >
      <div className={styles.article_body_wrapper_text_section}>
        <span className={classNames(styles.cardTitle, styles.truncateHeadline)}>
          {data.data.title ? <PrismicRichText field={data.data.title} /> : null}
        </span>
        <span
          className={classNames(
            styles.cardDescription,
            styles.truncateSubheadline
          )}
        >
          {data.data.description ? (
            <PrismicRichText field={data.data.description} />
          ) : null}
        </span>
      </div>
      <Link href={linkUrl} target={linkTarget}>
        <button
          className={classnames(
            styles.btn,
            styles.tertiary_black,
            styles.articleBtn
          )}
          name={buttonName}
        >
          {capitalizeString(urlData.buttonlinktext)}
        </button>
      </Link>
    </div>
  );

  const imgWrapper = (
    <div
      className={
        isNewsroomLayout ? styles.news_image_wrapper : styles.image_wrapper
      }
    >
      <Image
        src={data.data.image.url}
        alt={data.data.image.alt || 'case study'}
        fill
        sizes="(max-width: 768px) 100vw, 25vw"
        style={{
          objectFit: isNewsroomLayout ? 'contain' : 'cover',
        }}
      />
    </div>
  );

  return (
    <Link
      className={classnames(
        styles.articleCardWrapper,
        onScreen?.isIntersecting ? styles.visible : '',
        variant === 'slide' ? styles.slideVariant : styles.gridVariant
      )}
      ref={wrapperRef}
      href={linkUrl}
      target={linkTarget}
    >
      {imgWrapper}
      {bodyWrapper}
    </Link>
  );
}
