import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import ArticleCard from '../../components/articleCard/articleCard';
import styles from '../../styles/slices/NewsListing.module.scss';

import { forwardRef } from 'react';
import CaseStudyCard from '../../components/caseStudyCard/caseStudyCard';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

const NewsListing = ({ slice, cards, query, articles }, ref) => {
  const [filters, setFilters] = useState([]);
  const [activeFilter, setActiveFilter] = useState('view all');
  const [activeIndex, setActiveIndex] = useState(slice.primary.itemstodisplay);
  const [filteredCards, setFilteredCards] = useState([]);
  const removeHiddenCards = (cards) => {
    cards = cards.filter(function (obj) {
      return obj.data.hidden !== true;
    });
    return cards;
  };
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);

  const updateFilter = (filter) => {
    setActiveIndex(slice.primary.itemstodisplay);
    setActiveFilter(filter);
    const sortCards = structuredClone(cards);
    sortCards.map((item) => {
      if (!item.data.sortingdate) {
        item.data.sortingdate = new Date(item.last_publication_date);
      } else {
        item.data.sortingdate = new Date(item.data.sortingdate);
      }
    });
    const sortedCards = sortCards.sort(function (a, b) {
      return b.data.sortingdate - a.data.sortingdate;
    });
    if (filter === 'view all') {
      setFilteredCards(removeHiddenCards(sortedCards));
    } else {
      const tempCards = sortedCards.filter(
        (item) => item.data.category === filter
      );
      setFilteredCards(removeHiddenCards(tempCards));
    }
  };

  const findNumberOfCardsByCategory = (category) => {
    if (category === 'view all') {
      return cards.length;
    } else {
      return filteredCards.length;
    }
  };

  useEffect(() => {
    const sortCards = structuredClone(cards);
    sortCards.map((item) => {
      if (!item.data.sortingdate) {
        item.data.sortingdate = new Date(item.last_publication_date);
      } else {
        item.data.sortingdate = new Date(item.data.sortingdate);
      }
    });
    const sortedCards = sortCards.sort(function (a, b) {
      return b.data.sortingdate - a.data.sortingdate;
    });
    setFilteredCards(removeHiddenCards(sortedCards));
    const initialActive = [];
    const filterTempArray = [{ value: 'view all', label: 'view all' }];
    sortedCards.map((item, index) => {
      item.layout = 'newsroom';
      initialActive[index] = false;
      filterTempArray.push({
        value: item.data.category,
        label: item.data.category,
      });
    });
    const filterArray = filterTempArray.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (item) => item.value === value.value && item.label === value.label
        )
    );
    setFilters(filterArray);
  }, []);

  useEffect(() => {
    if (query.category) {
      const tempCategory = filters.find(
        (element) => element.value === decodeURIComponent(query.category)
      );
      if (tempCategory) {
        updateFilter(tempCategory.value);
      }
    }
  }, [query.category, filters]);

  useEffect(() => {
    if (query.tag) {
      setActiveIndex(slice.primary.itemstodisplay);
      setActiveFilter('view all');
      const tempTags = articles.results.filter((element) =>
        element.tags.includes(decodeURIComponent(query.tag))
      );
      const sortCards = structuredClone(cards);
      const tempCards = [];
      tempTags.map((item) => {
        const tempCard = sortCards.find(
          (element) => element.id === item.data.cardlink.id
        );
        tempCards.push(tempCard);
      });
      tempCards.map((item) => {
        if (item && !item.data.sortingdate) {
          item.data.sortingdate = new Date(item.last_publication_date);
        } else if (item && item.data.sortingdate) {
          item.data.sortingdate = new Date(item.data.sortingdate);
        }
      });
      const sortedCards = tempCards.sort(function (a, b) {
        return b.data.sortingdate - a.data.sortingdate;
      });
      setFilteredCards(removeHiddenCards(sortedCards));
    }
  }, [query.tag, articles]);

  return (
    <section
      className={classnames(
        styles.newsListingWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      ref={ref}
    >
      {query.tag && (
        <p className={styles.tagLine}>
          {filteredCards.length} results found for tag "{query.tag}"
        </p>
      )}
      <div className={styles.articleWrapper} ref={wrapperRef}>
        {filteredCards.map((item, index) => {
          return (
            (activeFilter === 'view all' ||
              activeFilter === item.data.category) && (
              <div
                className={classnames(
                  styles.article,
                  index < activeIndex ? styles.active : ''
                )}
                key={index}
              >
                <CaseStudyCard data={item} />
              </div>
            )
          );
        })}
      </div>
      <div className={styles.btnWrapper}>
        <button
          name="show more"
          onClick={() => {
            setActiveIndex(activeIndex + slice.primary.itemstodisplay);
          }}
          className={classnames(
            styles.btn,
            styles.primary_red,
            activeIndex < findNumberOfCardsByCategory(activeFilter)
              ? styles.visible
              : '',
            query.tag && activeIndex < findNumberOfCardsByCategory('tag')
              ? styles.visibleTagOverride
              : '',
            query.tag && activeIndex >= findNumberOfCardsByCategory('tag')
              ? styles.invisibleTagOverride
              : ''
          )}
        >
          {slice.primary.loadmorebuttontext}
        </button>
      </div>
    </section>
  );
};

export default forwardRef(NewsListing);
