import React, { useState, useEffect, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';
import styles from '../../styles/slices/Accordion.module.scss';
import Filters from '../../components/filters/filters';
import { forwardRef } from 'react';
import Image from 'next/image';
import accordionMinus from '../../public/accordion-minus.svg';
import accordionPlus from '../../public/accordion-plus.svg';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

const Accordion = ({ slice }, ref) => {
  const [active, setActive] = useState([]);
  const [filters, setFilters] = useState([]);
  const [activeFilter, setActiveFilter] = useState('view all');
  const elemHeight = useRef();
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  const heights = [];
  const [heightMap] = useState(new Map());
  const onRef = (node) => {
    if (node) {
      elemHeight.current = node;
      heights.push(elemHeight.current?.scrollHeight);
    }
  };
  const toggle = (index) => {
    const newActive = active.map((elem, i) => {
      if (index === i) {
        return !elem;
      } else return elem;
    });
    setActive(newActive);
  };

  const updateFilter = (filter) => {
    setActiveFilter(filter);
  };

  useEffect(() => {
    const initialActive = [];
    const filterArray = [{ value: 'view all', label: 'view all' }];
    slice.items.map((item, index) => {
      initialActive[index] = false;
      filterArray.push({ value: item.category, label: item.category });
    });
    const filteredArray = filterArray.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (item) => item.value === value.value && item.label === value.label
        )
    );
    heights.map((item, index) => {
      heightMap.set(index, item);
    });
    setFilters(filteredArray);
    setActive(initialActive);
  }, []);

  return (
    <section
      className={classnames(
        styles.accordionWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div ref={wrapperRef}>
        {slice?.primary.displayfilters && (
          <Filters
            filters={filters}
            updateFn={updateFilter}
            activeFilter={activeFilter}
          ></Filters>
        )}
        {slice?.items.map((item, index) => {
          return (
            (activeFilter === 'view all' || activeFilter === item.category) && (
              <div className={styles.accordionItem} key={index}>
                <div className={styles.contentWrapper}>
                  <div
                    onClick={() => toggle(index)}
                    key={index}
                    className={styles.titleWrapper}
                  >
                    <PrismicRichText field={item.title} />
                    {active[index] ? (
                      <Image src={accordionMinus} alt="minus icon" />
                    ) : (
                      <Image src={accordionPlus} alt="plus icon" />
                    )}
                  </div>
                  <div
                    ref={onRef}
                    className={classnames(
                      styles.textWrapper,
                      active[index] ? styles.show : null
                    )}
                    style={
                      active[index]
                        ? {
                            maxHeight: heightMap.get(index) + 35 + 'px',
                          }
                        : { maxHeight: 0 }
                    }
                  >
                    {item.description.map((item, _index) =>
                      item.type !== 'preformatted' ? (
                        <PrismicRichText
                          key={item.type + index + _index}
                          field={[item]}
                        />
                      ) : (
                        <div
                          key={item.type + index + _index}
                          className="preformatted"
                          dangerouslySetInnerHTML={
                            item && { __html: item.text }
                          }
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            )
          );
        })}
      </div>
    </section>
  );
};

export default forwardRef(Accordion);
