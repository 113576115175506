import { useContext, useState } from 'react';
import Link from 'next/link';
import classnames from 'classnames';
import { SliceZone } from '@prismicio/react';
import { components } from '../../slices';
import { MobileContext } from '../../context/mobileContext';
import styles from '../../styles/slices/NavigationMenu.module.scss';
import { useRouter } from 'next/router';

const NavigationMenu = ({ slice, headerSubmenu }) => {
  const [subMenuVisible, setSubMenuVisible] = useState(false);
  const isMobile = useContext(MobileContext);
  const router = useRouter();

  return (
    <section className={styles.navigationMenuWrapper}>
      <ul className={styles.menu_list}>
        {slice.items.map((elem, index) => (
          <li
            key={index}
            className={classnames(
              elem.hassubmenu && styles.hasSubmenu,
              subMenuVisible === elem.submenu.id && styles.subMenuVisible,
              router.asPath === elem.link.url ? styles.active : null
            )}
            onClick={() => {
              elem.hassubmenu
                ? () =>
                    setSubMenuVisible(
                      subMenuVisible && subMenuVisible === elem.submenu.id
                        ? false
                        : elem.submenu.id
                    )
                : undefined;
            }}
            onMouseOver={
              elem.hassubmenu && !isMobile
                ? () => setSubMenuVisible(elem.submenu.id)
                : undefined
            }
            onMouseOut={
              elem.hassubmenu && !isMobile
                ? () => setSubMenuVisible(false)
                : undefined
            }
          >
            {!elem.hassubmenu && (
              <Link href={elem.link.url} target={elem.link.target}>
                {elem.title}
              </Link>
            )}
            {elem.hassubmenu && <span>{elem.title}</span>}

            {subMenuVisible &&
              subMenuVisible === elem.submenu.id &&
              elem.hassubmenu && (
                <SliceZone
                  slices={[
                    headerSubmenu.find(
                      (element) => element.id === elem.submenu.id
                    ),
                  ]}
                  components={{
                    navigationsubmenu: components.navigation_sub_menu,
                  }}
                />
              )}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default NavigationMenu;
