// Code generated by Slice Machine. DO NOT EDIT.

import Accordion from './Accordion';
import AnchorLinks from './AnchorLinks';
import Awards from './Awards';
import Benefits from './Benefits';
import Callout from './Callout';
import CaseStudyListing from './CaseStudyListing';
import ComparisonTable from './ComparisonTable';
import ContactForm from './ContactForm';
import CustomerStories from './CustomerStories';
import ExpandableCards from './ExpandableCards';
import ExpandableProfile from './ExpandableProfile';
import FeaturesCarousel from './FeaturesCarousel';
import FooterLegalMenu from './FooterLegalMenu';
import FooterMenu from './FooterMenu';
import FooterSocial from './FooterSocial';
import FourColumnCardFeed from './FourColumnCardFeed';
import FourColumnSteps from './FourColumnSteps';
import HomeHero from './HomeHero';
import InternalHero from './InternalHero';
import MixedContent from './MixedContent';
import NavigationMenu from './NavigationMenu';
import NavigationSubMenu from './NavigationSubMenu';
import NewsListing from './NewsListing';
import PartnerCarousel from './PartnerCarousel';
import Profile from './Profile';
import Quote from './Quote';
import SearchResults from './SearchResults';
import StaticTwoColumn from './StaticTwoColumn';
import StatsCards from './StatsCards';
import Testimonials from './Testimonials';
import ThreeColumnCaseStudyFeed from './ThreeColumnCaseStudyFeed';
import ThreeColumnStatCards from './ThreeColumnStatCards';
import TwoColumnText from './TwoColumnText';
import V2GCalculator from './V2GCalculator';
import VideoBlock from './VideoBlock';
import Wysiwyg from './Wysiwyg';
import Form from './Form';
import PartnersGrid from './PartnersGrid';

export {
  Accordion,
  AnchorLinks,
  Awards,
  Benefits,
  Callout,
  CaseStudyListing,
  ComparisonTable,
  ContactForm,
  CustomerStories,
  ExpandableCards,
  ExpandableProfile,
  FeaturesCarousel,
  Form,
  FooterLegalMenu,
  FooterMenu,
  FooterSocial,
  FourColumnCardFeed,
  FourColumnSteps,
  HomeHero,
  InternalHero,
  MixedContent,
  NavigationMenu,
  NavigationSubMenu,
  NewsListing,
  PartnerCarousel,
  PartnersGrid,
  Profile,
  Quote,
  SearchResults,
  StaticTwoColumn,
  StatsCards,
  Testimonials,
  ThreeColumnCaseStudyFeed,
  ThreeColumnStatCards,
  TwoColumnText,
  V2GCalculator,
  VideoBlock,
  Wysiwyg,
};

export const components = {
  accordion: Accordion,
  anchor_links: AnchorLinks,
  awards: Awards,
  benefits: Benefits,
  callout: Callout,
  case_study_listing: CaseStudyListing,
  comparison_table: ComparisonTable,
  contact_form: ContactForm,
  customer_stories: CustomerStories,
  expandable_cards: ExpandableCards,
  expandable_profile: ExpandableProfile,
  features_carousel: FeaturesCarousel,
  form: Form,
  footer_legal_menu: FooterLegalMenu,
  footer_menu: FooterMenu,
  footer_social: FooterSocial,
  four_column_card_feed: FourColumnCardFeed,
  four_column_steps: FourColumnSteps,
  hero: HomeHero,
  internal_hero: InternalHero,
  mixed_content: MixedContent,
  navigation_menu: NavigationMenu,
  navigation_sub_menu: NavigationSubMenu,
  news_listing: NewsListing,
  partner_carousel: PartnerCarousel,
  partners_grid: PartnersGrid,
  profile: Profile,
  quote: Quote,
  search_results: SearchResults,
  two_column_panel: StaticTwoColumn,
  stats_cards: StatsCards,
  testimonials: Testimonials,
  three_column_case_study_feed: ThreeColumnCaseStudyFeed,
  three_column_stat_cards: ThreeColumnStatCards,
  two_column_text: TwoColumnText,
  v2_g_calculator: V2GCalculator,
  video_block: VideoBlock,
  wysiwyg: Wysiwyg,
};
