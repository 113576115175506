import React, { useState } from 'react';
import classnames from 'classnames';
import { PrismicRichText } from '@prismicio/react';
import styles from '../../styles/slices/FooterMenu.module.scss';
import Link from 'next/link';
import Image from 'next/image';

const FooterMenu = ({ slice }) => {
  return (
    <section>
      <div className={styles.footer_menu}>
        <ul className={styles.footer_menu_list}>
          {slice.items.map((item, index) => (
            <li
              key={index}
              className={classnames(
                styles.footer_menu,
                item.visibleonmobile === false ? styles.hide_mobile : null
              )}
            >
              {item.ctalink.url ? (
                <Link
                  href={item.ctalink.url}
                  passHref
                  target={item.ctalink.target}
                >
                  {item.icon.url ? (
                    <Image
                      src={item.icon.url}
                      alt={item.icon.alt || 'menu icon'}
                      width={item.icon.dimensions.width}
                      height={item.icon.dimensions.height}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                      }}
                    />
                  ) : null}
                  <p className={item.icon.url ? styles.hasIcon : null}>
                    {item.ctatext}
                  </p>
                </Link>
              ) : (
                'test'
              )}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default FooterMenu;
