import styles from '../../styles/components/ExpandedCard.module.scss';
import React, { useRef, useEffect, useContext } from 'react';
import { MobileContext } from '../../context/mobileContext';
import { PrismicRichText } from '@prismicio/react';
import Image from 'next/image';
import { SliceZone } from '@prismicio/react';
import { Callout, Benefits, FeaturesCarousel } from '../../slices';
import plusIcon from '../../public/plus.svg';
import minusIcon from '../../public/accordion-minus.svg';
import closeIcon from '../../public/close.svg';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import classnames from 'classnames';

export default function ExpandedCard({ data, active, activeBtn, onToggleFn }) {
  const cardRef = useRef(null);
  const isMobile = useContext(MobileContext);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  useEffect(() => {
    if (active === data.id) {
      cardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }, [active, data.id]);

  return (
    <div className={styles.expandedCardWrapper}>
      <div
        className={classnames(
          styles.cardWrapper,
          onScreen?.isIntersecting ? styles.visible : ''
        )}
        ref={wrapperRef}
      >
        <div className={styles.textWrapper}>
          {data.data.cardsubtitle?.length > 0 && (
            <div className={styles.subtitle}>
              <PrismicRichText field={data.data.cardsubtitle} />
            </div>
          )}
          {data.data.cardtitle?.length > 0 && (
            <div className={styles.cardtitle}>
              <PrismicRichText field={data.data.cardtitle} />
            </div>
          )}
        </div>
        {data.data.cardimage?.url && (
          <Image
            src={data.data.cardimage.url}
            alt={data.data.cardimage.alt || 'expanded'}
            width={data.data.cardimage.dimensions.width}
            height={data.data.cardimage.dimensions.height}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
            sizes="(max-width: 768px) 100vw, 16.667vw"
          />
        )}
      </div>
      <div className={styles.click_expand} onClick={() => onToggleFn(data)}>
        {activeBtn === data.id ? (
          <Image src={minusIcon} alt="expand icon" />
        ) : (
          <Image src={plusIcon} alt="expand icon" />
        )}
      </div>
      {isMobile && active === data.id && (
        <div className={styles.expanded} ref={cardRef}>
          <div className={styles.contentWrapper}>
            <Image
              className={styles.close_trigger}
              src={closeIcon}
              alt="close icon"
              onClick={() => onToggleFn(null)}
            />
            {data.data.mainsubtitle?.length > 0 && (
              <div className={styles.mainsubtitle}>
                <PrismicRichText field={data.data.mainsubtitle} />
              </div>
            )}
            {data.data.maintitle?.length > 0 && (
              <div className={styles.maintitle}>
                <PrismicRichText field={data.data.maintitle} />
              </div>
            )}
            {data.data.maindescription?.length > 0 && (
              <div className={styles.maindescription}>
                <PrismicRichText field={data.data.maindescription} />
              </div>
            )}
          </div>
          <SliceZone
            slices={data.data.slices}
            components={{
              callout: (props) => {
                return <Callout slice={props.slice} {...props} />;
              },
              benefits: (props) => {
                return <Benefits slice={props.slice} {...props} />;
              },
              features_carousel: (props) => {
                return <FeaturesCarousel slice={props.slice} {...props} />;
              },
            }}
          />
        </div>
      )}
    </div>
  );
}
