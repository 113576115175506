import React, {
  forwardRef,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { PrismicRichText } from '@prismicio/react';
import ExpandedCard from '../../components/expandedCard/expandedCard';
import { MobileContext } from '../../context/mobileContext';
import { SliceZone } from '@prismicio/react';
import styles from '../../styles/slices/ExpandableCards.module.scss';
import { Callout, Benefits, FeaturesCarousel } from '../../slices';
import closeIcon from '../../public/close.svg';
import Image from 'next/image';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import classnames from 'classnames';

const ExpandableCards = ({ slice, cards }, ref) => {
  const isMobile = useContext(MobileContext);
  const [expandData, setExpandData] = useState(null);
  const [filteredCards, setFilteredCards] = useState([]);
  const [active, setActive] = useState(null);
  const cardRef = useRef(null);
  const [activeBtn, setActiveBtn] = useState(false);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  useEffect(() => {
    const tempFilteredCards = [];
    slice.items.map((item, index) => {
      const found = cards.find((element) => item.cards.id === element.id);
      tempFilteredCards.push(found);
    });
    setFilteredCards(tempFilteredCards);
  }, [slice, cards]);

  useEffect(() => {
    if (expandData) {
      cardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }, [expandData]);

  const onToggleFn = (data) => {
    if (data && data.id) {
      setActiveBtn(data.id);
      if (isMobile) {
        setActive(data.id);
      } else {
        setExpandData(data);
      }
    } else {
      setActiveBtn(false);
      if (isMobile) {
        setActive(null);
      } else {
        setExpandData(null);
      }
    }
  };

  return (
    <section
      className={classnames(
        styles.expandableCardsWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      ref={ref}
      id={slice.primary.uid || ''}
    >
      <div className={styles.textWrapper} ref={wrapperRef}>
        {slice.primary.subtitle?.length > 0 && (
          <div className={styles.subtitle}>
            <PrismicRichText field={slice.primary.subtitle} />
          </div>
        )}
        {slice.primary.title?.length > 0 && (
          <div className={styles.title}>
            <PrismicRichText field={slice.primary.title} />
          </div>
        )}
        {slice.primary.description?.length > 0 && (
          <div className={styles.description}>
            <PrismicRichText field={slice.primary.description} />
          </div>
        )}
      </div>
      <div className={styles.cardsWrapper}>
        {filteredCards.map((item, index) => {
          return (
            <ExpandedCard
              key={index}
              data={item}
              onToggleFn={onToggleFn}
              active={active}
              activeBtn={activeBtn}
            />
          );
        })}
      </div>
      {!isMobile && expandData && (
        <div className={styles.expanded} ref={cardRef}>
          <div className={styles.contentWrapper}>
            <Image
              className={styles.close_trigger}
              src={closeIcon}
              alt="close icon"
              onClick={() => onToggleFn(null)}
            />
            {expandData.data.mainsubtitle?.length > 0 && (
              <div className={styles.mainsubtitle}>
                <PrismicRichText field={expandData.data.mainsubtitle} />
              </div>
            )}
            {expandData.data.maintitle?.length > 0 && (
              <div className={styles.maintitle}>
                <PrismicRichText field={expandData.data.maintitle} />
              </div>
            )}
            {expandData.data.maindescription?.length > 0 && (
              <div className={styles.maindescription}>
                <PrismicRichText field={expandData.data.maindescription} />
              </div>
            )}
          </div>
          <SliceZone
            slices={expandData.data.slices}
            components={{
              callout: (props) => {
                return <Callout slice={props.slice} {...props} />;
              },
              benefits: (props) => {
                return <Benefits slice={props.slice} {...props} />;
              },
              features_carousel: (props) => {
                return <FeaturesCarousel slice={props.slice} {...props} />;
              },
            }}
          />
        </div>
      )}
    </section>
  );
};

export default forwardRef(ExpandableCards);
