import React, { forwardRef, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import styles from '../../styles/slices/Wysiwyg.module.scss';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import classnames from 'classnames';
import { customLink } from '../../components/customLink';

const Wysiwyg = ({ slice }, ref) => {
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  return (
    <section
      className={classnames(
        styles.wysiwygWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div className={styles.wysiwygContainer} ref={wrapperRef}>
        <div className={styles.wysiwygSubtitle}>
          <PrismicRichText field={slice.primary.subtitle} />
        </div>
        <div className={styles.wysiwygTitle}>
          <PrismicRichText field={slice.primary.title} />
        </div>
        <div className={styles.wysiwygTextarea}>
          <PrismicRichText field={slice.primary.textarea} components={{
            hyperlink: customLink,
          }}/>
        </div>
      </div>
    </section>
  );
};

export default forwardRef(Wysiwyg);
