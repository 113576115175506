import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';
import Script from 'next/script';
import React, { forwardRef, useEffect } from 'react';
import styles from '../../styles/slices/Form.module.scss';
import states from '../../utils/states';

const Form = ({ slice, success, slug }, ref) => {
  const groupFormFields = (fields) => {
    const result = [];
    let tempArray = [];

    fields.forEach((field) => {
      if (field.field_type === 'textarea') {
        if (tempArray.length > 0) {
          result.push(tempArray);
          tempArray = [];
        }
        result.push([field]); // Add textarea field alone in its own row
      } else {
        tempArray.push(field);
        if (tempArray.length === 2) {
          result.push(tempArray);
          tempArray = [];
        }
      }
    });

    // Add any remaining fields in tempArray to result
    if (tempArray.length > 0) {
      result.push(tempArray);
    }

    return result;
  };

  const rows =
    Array.isArray(slice.items) &&
    slice.items.length > 0 &&
    groupFormFields(slice.items, 2);

  useEffect(() => {
    const timestamp = () => {
      const response = document.getElementById('g-recaptcha-response');
      if (response == null || response.value.trim() == '') {
        const elems = JSON.parse(
          document.getElementsByName('captcha_settings')[0].value
        );
        elems['ts'] = JSON.stringify(new Date().getTime());
        document.getElementsByName('captcha_settings')[0].value =
          JSON.stringify(elems);
      }
    };

    let interval;
    if (!success) {
      interval = setInterval(timestamp, 500);
    } else {
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [success]);

  return (
    <div className={styles.formContainer}>
      <>
        {!success && (
          <Script
            id="recaptcha"
            src="https://www.google.com/recaptcha/api.js"
          />
        )}
        <section
          className={styles.formWrapper}
          id={slice.primary.uid || ''}
          ref={ref}
        >
          {!success && (
            <>
              {slice.primary.title ? (
                <div className={styles.formTitle}>
                  <PrismicRichText field={slice.primary.title} />
                </div>
              ) : null}
              {slice.primary.description ? (
                <div className={styles.formDescription}>
                  <PrismicRichText field={slice.primary.description} />
                </div>
              ) : null}

              <form action="/api/form-submit" name="dynamicForm" method="post">
                <input
                  type="hidden"
                  name="captcha_settings"
                  value='{"keyname":"FE_Website_SetupByJimmy","fallback":"true","orgId":"00D2E000001FNry","ts":""}'
                />
                <input type="hidden" name="oid" value="00D2E000001FNry" />

                <input type="hidden" name="_clientID" value="546004065" />
                <input
                  type="hidden"
                  name="_deExternalKey"
                  value={slice.primary.external_key}
                />
                <input type="hidden" name="_action" value="add" />
                <input type="hidden" name="_returnXML" value="0" />
                <input
                  type="hidden"
                  name="_successURL"
                  value={`${process.env.NEXT_PUBLIC_BASE_URL}${slug}?state=success`}
                />
                <input
                  type="hidden"
                  name="_errorURL"
                  value={`${process.env.NEXT_PUBLIC_BASE_URL}${slug}?state=error`}
                />
                {rows.length > 0 &&
                  rows.map((fieldsRow) => {
                    return (
                      <div className={styles.formRow}>
                        {fieldsRow.map((field) => {
                          if (field.field_type === 'text') {
                            return (
                              <div className={styles.formItem}>
                                <label htmlFor={field.field_name}>
                                  {field.field_label}
                                </label>
                                <input
                                  id={field.field_name}
                                  maxLength="80"
                                  name={field.field_name}
                                  size="20"
                                  type="text"
                                  placeholder={field.field_placeholder}
                                  required={field.required_field}
                                />
                              </div>
                            );
                          } else if (field.field_type === 'dropdown') {
                            return (
                              <div className={styles.formItem}>
                                <span className={styles.selectLabel}>
                                  {field.field_label || 'State*'}
                                </span>
                                <select
                                  id={field.field_name}
                                  name={field.field_name}
                                  title={field.field_label}
                                  required={field.required_field}
                                >
                                  <option value="">
                                    {field.field_placeholder ||
                                      'Select a state'}
                                  </option>
                                  {states.map((state) => (
                                    <option key={state} value={state}>
                                      {state}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            );
                          } else if (field.field_type === 'textarea') {
                            return (
                              <div
                                className={classnames(
                                  styles.formItem,
                                  styles.full
                                )}
                              >
                                <label htmlFor={field.field_name}>
                                  {field.field_label}
                                </label>
                                <textarea
                                  id={field.field_name}
                                  name={field.field_name}
                                  rows="3"
                                  type="text"
                                  wrap="soft"
                                  required={field.required_field}
                                ></textarea>
                              </div>
                            );
                          }
                        })}
                      </div>
                    );
                  })}
                <div className={styles.formRow}>
                  <div
                    className="g-recaptcha"
                    data-sitekey="6Lc8HT8jAAAAAC4jBH7qCIGLKJp2Jg-clSAKirNj"
                  ></div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.submitContainer}>
                    <input
                      className={styles.submit_btn}
                      type="submit"
                      name="submit"
                    />
                  </div>
                </div>
              </form>
            </>
          )}
          {success && (
            <>
              {slice.primary.form_success_text ? (
                <div className={styles.successPage}>
                  <div className={styles.successTitle}>
                    <PrismicRichText field={slice.primary.form_success_text} />
                  </div>
                </div>
              ) : null}
            </>
          )}
        </section>
      </>
    </div>
  );
};

export default forwardRef(Form);
