import React, { useContext, useState, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import styles from '../../styles/slices/Profile.module.scss';
import Image from 'next/image';
import { MobileContext } from '../../context/mobileContext';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import classnames from 'classnames';
import plusIcon from '../../public/plus.svg';
import closeIcon from '../../public/close.svg';

const Profile = ({ slice, onToggleFn, fourCols = false }) => {
  const isMobile = useContext(MobileContext);
  const [activeBio, setActiveBio] = useState(null);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);

  const onToggleClick = (slice) => {
    if (isMobile) {
      setActiveBio(slice);
    } else {
      onToggleFn(slice);
    }
  };

  return (
    <section
      className={classnames(
        styles.profileWrapper,
        onScreen?.isIntersecting ? styles.visible : '',
        fourCols ? styles.fourCols : ''
      )}
      ref={wrapperRef}
    >
      <div className={styles.profile_summary}>
        <div className={styles.wrapper_img}>
          {slice.primary.photo.url && (
            <Image
              src={slice.primary.photo.url}
              alt={slice.primary.photo.alt || 'profile'}
              width={slice.primary.photo.dimensions.width}
              height={slice.primary.photo.dimensions.height}
              style={{
                width: '100%',
                height: 'auto',
              }}
              sizes="(max-width: 768px) 106px, 21vw"
            />
          )}
        </div>
        <div className={styles.wrapper_profile_details}>
          <p className={styles.jobtitle}>{slice.primary.jobtitle}</p>
          <p className={styles.name}>{slice.primary.name}</p>
        </div>
        <div
          className={styles.click_expand}
          onClick={() => onToggleClick(slice)}
        >
          <Image src={plusIcon} alt="plus" />
        </div>
      </div>
      {activeBio && (
        <div className={styles.container_detail}>
          <Image
            className={styles.close_trigger}
            src={closeIcon}
            alt="close"
            onClick={() => onToggleClick(null)}
          />
          <div className={styles.box_header}>
            {activeBio.primary.photo.url && (
              <span>
                <Image
                  src={activeBio.primary.photo.url}
                  alt={activeBio.primary.photo.alt || 'bio'}
                  width={activeBio.primary.photo.dimensions.width}
                  height={activeBio.primary.photo.dimensions.height}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                  sizes="(max-width: 768px) 64px, 4.444vw"
                />
              </span>
            )}
            <div className={styles.right_area}>
              <p className={styles.jobtitle}>{activeBio.primary.jobtitle}</p>
              <p className={styles.name}>{activeBio.primary.name}</p>
            </div>
          </div>
          <div className={styles.box_content}>
            <PrismicRichText field={activeBio.primary.bio} />
          </div>
        </div>
      )}
    </section>
  );
};

export default Profile;
