import React, { useEffect, useRef, useState, useContext } from 'react';
import styles from '../../styles/slices/AnchorLinks.module.scss';
import classnames from 'classnames';
import Link from 'next/link';
import { forwardRef } from 'react';
import { MobileContext } from '../../context/mobileContext';

const AnchorLinks = ({ slice, componentRefs }, ref) => {
  const [idComponents, setIdComponents] = useState([]);
  const [active, setActive] = useState();
  const [headerHeight, setHeaderHeight] = useState();
  const wrapperAnchorRef = useRef(null);
  const anchorRefs = useRef([]);
  const isMobile = useContext(MobileContext);

  const updatePosition = () => {
    let active = '';
    idComponents?.map((item) => {
      if (item.getBoundingClientRect().y - headerHeight < 1) {
        active = item.getAttribute('id');
      }
    });
    setActive(active);

    if (isMobile) {
      anchorRefs.current.map((item) => {
        if (item.getAttribute('data-active') === 'active') {
          const leftComp = item.offsetLeft;
          const widthComp = item.offsetWidth;
          wrapperAnchorRef.current.scrollLeft = leftComp - widthComp / 2 - 30;
        }
      });
    }
  };

  const activeCategory = (e, id) => {
    e.preventDefault();
    const topComp = componentRefs.current.find(
      (element) => element.getAttribute('id') === id
    );

    if (topComp) {
      window.scrollTo({
        top: topComp.offsetTop - headerHeight,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (isMobile) {
        setHeaderHeight(200);
      } else {
        setHeaderHeight((window.innerWidth * 244) / 1440);
      }
    }
  }, []);

  useEffect(() => {
    const tempComponents = [];
    componentRefs?.current.map((item) => {
      if (item && item.getAttribute('id') && item.getAttribute('id') !== '') {
        tempComponents.push(item);
      }
    });
    setIdComponents(tempComponents);
  }, [componentRefs]);

  useEffect(() => {
    window.addEventListener('scroll', updatePosition);

    updatePosition();

    return () => window.removeEventListener('scroll', updatePosition);
  }, [idComponents]);

  return (
    <section className={classnames(styles.anchorLinksWrapper)} ref={ref}>
      <div className={styles.wrapperAnchor} ref={wrapperAnchorRef}>
        {slice.items.map((item, index) => {
          return (
            <Link
              key={index}
              href={`#${item.anchorlink}`}
              ref={(element) => (anchorRefs.current[index] = element)}
              onClick={(e) => activeCategory(e, item.anchorlink, index)}
              className={item.anchorlink === active ? styles.active : undefined}
              data-active={item.anchorlink == active ? 'active' : undefined}
            >
              {item.label}
            </Link>
          );
        })}
      </div>
    </section>
  );
};

export default forwardRef(AnchorLinks);
