import React, { forwardRef, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import Image from 'next/image';
import classnames from 'classnames';
import styles from '../../styles/slices/ComparisonTable.module.scss';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

const ComparisonTable = ({ slice }, ref) => {
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  return (
    <section
      className={classnames(
        styles.comparisonTableWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div className={styles.header_wrapper} ref={wrapperRef}>
        {slice.primary.subtitle.length > 0 && (
          <div className={styles.subtitle}>
            <PrismicRichText field={slice.primary.subtitle} />
          </div>
        )}
        {slice.primary.title && (
          <div className={styles.title}>
            <PrismicRichText field={slice.primary.title} />
          </div>
        )}
      </div>
      <div className={styles.mainWrapper}>
        <div className={styles.internalWrapper}>
          <div className={styles.left}>
            <div className={styles.boxSubtitle}>
              <PrismicRichText field={slice.primary.leftsubtitle} />
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.boxSubtitle}>
              <PrismicRichText field={slice.primary.rightsubtitle} />
            </div>
          </div>
        </div>
        <div className={styles.internalWrapper}>
          <div className={styles.left}>
            <div className={styles.boxTitle}>
              <PrismicRichText field={slice.primary.lefttitle} />
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.boxTitle}>
              <PrismicRichText field={slice.primary.righttitle} />
            </div>
          </div>
        </div>
        <div className={styles.internalWrapper}>
          <div className={styles.left}>
            <div className={styles.boxDesc}>
              <PrismicRichText field={slice.primary.leftdescription} />
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.boxDesc}>
              <PrismicRichText field={slice.primary.rightdescription} />
            </div>
          </div>
        </div>
        {slice.items.map((item, index) => {
          return (
            <div className={styles.internalWrapper} key={index}>
              <div className={classnames(styles.left, styles.card)}>
                <div className={styles.wrapperCard}>
                  <div className={styles.image}>
                    <Image
                      src={item.leftcardicon.url}
                      alt={item.leftcardicon.alt || 'left card'}
                      width={item.leftcardicon.dimensions.width}
                      height={item.leftcardicon.dimensions.height}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                      }}
                    />
                  </div>
                  <div
                    className={classnames(
                      styles.description,
                      item.leftcardstate ? '' : styles.stateFalse
                    )}
                  >
                    <PrismicRichText field={item.leftcarddescription} />
                  </div>
                </div>
              </div>
              <div className={classnames(styles.right, styles.card)}>
                <div className={styles.wrapperCard}>
                  <div className={styles.image}>
                    <Image
                      src={item.rightcardicon.url}
                      alt={item.rightcardicon.alt || 'right card'}
                      width={item.rightcardicon.dimensions.width}
                      height={item.rightcardicon.dimensions.height}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                      }}
                    />
                  </div>
                  <div
                    className={classnames(
                      styles.description,
                      item.rightcardstate ? '' : styles.stateFalse
                    )}
                  >
                    <PrismicRichText field={item.rightcarddescription} />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default forwardRef(ComparisonTable);
