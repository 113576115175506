import * as Slider from '@radix-ui/react-slider';
import React from 'react';
import styles from '../../styles/components/V2GCalculatorSlider.module.scss';

function V2GCalculatorSlider({ chargers, setChargers, maxVal = 5 }) {
  const handleChange = (value) => {
    if (value !== undefined && value !== null) {
      setChargers(value);
    }
  };

  return (
    <div className={styles.sliderContainer}>
      <div className={styles.minmax}>
        <span>1</span>
        <span>{maxVal}</span>
      </div>
      <Slider.Root
        name="Number of V2G Chargers"
        className={styles.root}
        defaultValue={[1]}
        min={1}
        max={maxVal}
        step={1}
        onValueChange={handleChange}
      >
        <Slider.Track className={styles.track}>
          <Slider.Range className={styles.range} />
        </Slider.Track>
        <Slider.Thumb className={styles.thumb}>
          <div>{chargers > maxVal ? maxVal : chargers}</div>
          <div />
        </Slider.Thumb>
      </Slider.Root>
      <div className={styles.markers}>
        {Array.from({ length: maxVal > 5 ? 10 : 5 }).map((_, i) => {
          const value = i + 1;
          return (
            <span key={i} className={styles.marker}>
              {value === 0 ? 1 : value}
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default V2GCalculatorSlider;
