import React, { useEffect, useState, forwardRef, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import Vimeo from '@u-wave/react-vimeo';
import styles from '../../styles/slices/Testimonials.module.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import classnames from 'classnames';
import Image from 'next/image';
import playIcon from '../../public/play_testimonials.svg';
import rightArrow from '../../public/arrow-right.svg';

const Testimonials = ({ slice }, ref) => {
  const vimeoRefs = useRef([]);
  const playBtnRef = useRef([]);
  const videoRefs = useRef([]);
  const slider = useRef(null);
  const [playVimeo, setPlayVimeo] = useState([]);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);

  useEffect(() => {
    const tempVimeoPlayArr = [];
    vimeoRefs.current.map((item, index) => {
      tempVimeoPlayArr[index] = true;
    });
    setPlayVimeo(tempVimeoPlayArr);
  }, []);

  const handlePlay = (index) => {
    videoRefs.current[index].play();
  };

  const handlePlayVimeo = (index) => {
    const tempVimeoPlayArr = [...playVimeo];
    tempVimeoPlayArr[index] = false;
    setPlayVimeo(tempVimeoPlayArr);
  };

  const PauseVideos = () => {
    videoRefs.current.map((item) => {
      item.pause();
    });
    const tempVimeoPlayArr = [...playVimeo];
    tempVimeoPlayArr.map((item, index) => {
      if (typeof tempVimeoPlayArr[index] !== 'undefined') {
        tempVimeoPlayArr[index] = true;
      }
    });
    setPlayVimeo(tempVimeoPlayArr);
  };

  const DisplayButtons = () => {
    playBtnRef.current.map((item) => {
      item.classList.remove(styles.inactive);
    });
    vimeoRefs.current.map((item) => {
      item.classList.remove(styles.inactive);
    });
  };

  const PauseAndNext = () => {
    PauseVideos();
    slider?.current?.slickNext();
    DisplayButtons();
  };

  const PauseAndPrev = () => {
    PauseVideos();
    slider?.current?.slickPrev();
    DisplayButtons();
  };

  const slickSettings = {
    dots: true,
    infinite: false,
    speed: 300,
    swipeToSlide: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    centerPadding: 0,
    variableWidth: true,
    arrows: true,
    focusOnSelect: true,
  };

  return (
    <section
      className={classnames(
        styles.testimonialsWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div className={styles.testimonialsContainer} ref={wrapperRef}>
        <div className={styles.testimonialsSubtitle}>
          <PrismicRichText field={slice.primary.subtitle} />
        </div>
        <div className={styles.testimonialsTitle}>
          <PrismicRichText field={slice.primary.title} />
        </div>
        <div className={styles.testimonialCarousel}>
          <Slider ref={slider} {...slickSettings}>
            {slice.items.map((item, index) => {
              return (
                <div
                  className={classnames(
                    styles.testimonialItem,
                    item.quote.length > 0 ? styles.textArea : '',
                    item.isembed ? styles.vimeoClass : ''
                  )}
                  key={index}
                >
                  {item.quote.length > 0 && (
                    <div className={styles.wrapperQuotes}>
                      <div className={styles.cardTitle}>
                        <PrismicRichText field={item.quote} />
                      </div>
                      <p className={styles.author}>{item.author}</p>
                    </div>
                  )}
                  {item.quote.length === 0 && !item.isembed && (
                    <>
                      <video
                        ref={(element) => (videoRefs.current[index] = element)}
                        src={item.video.url}
                        controls
                        type="video/mp4"
                        onPause={() => {
                          PauseVideos();
                          DisplayButtons();
                        }}
                        onPlay={() => {
                          playBtnRef.current[index].classList.add(
                            styles.inactive
                          );
                        }}
                      ></video>
                      <div
                        className={styles.imagePlay}
                        ref={(element) => (playBtnRef.current[index] = element)}
                        onClick={() => {
                          playBtnRef.current[index].classList.add(
                            styles.inactive
                          );
                          handlePlay(index);
                        }}
                      >
                        <div className={styles.imagePlayWrapper}>
                          <Image src={playIcon} alt="Play" />
                        </div>
                      </div>
                    </>
                  )}
                  {item.quote.length === 0 && item.isembed && (
                    <>
                      <Vimeo
                        video={item.embed.embed_url}
                        paused={playVimeo[index]}
                        onPause={() => {
                          PauseVideos();
                          DisplayButtons();
                        }}
                        onPlay={() => {
                          vimeoRefs.current[index].classList.add(
                            styles.inactive
                          );
                        }}
                      />
                      <div
                        className={styles.imagePlay}
                        ref={(element) => (vimeoRefs.current[index] = element)}
                        onClick={() => {
                          vimeoRefs.current[index].classList.add(
                            styles.inactive
                          );
                          handlePlayVimeo(index);
                        }}
                      >
                        <div className={styles.imagePlayWrapper}>
                          <Image src={playIcon} alt="Play" />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </Slider>
          <div className={styles.partners_arrows}>
            <span>
              <Image
                alt="prev arrow"
                className={styles.prev_arrow}
                src={rightArrow}
                onClick={PauseAndPrev}
              />
            </span>
            <span>
              <Image
                alt="next arrow"
                className={styles.next_arrow}
                src={rightArrow}
                onClick={PauseAndNext}
              />
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default forwardRef(Testimonials);
