import React, { forwardRef, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import styles from '../../styles/slices/PartnerCarousel.module.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import classnames from 'classnames';
import Image from 'next/image';
import arrowRight from '../../public/arrow-right.svg';

const PartnerCarousel = ({ slice }, ref) => {
  const slider = useRef(null);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);

  let slickSettings = {
    className: 'carouselPartners',
    dots: true,
    infinite: true,
    speed: 300,
    swipeToSlide: true,
    slidesToScroll: 1,
    slidesToShow: 5,
    centerPadding: 0,
    variableWidth: true,
    arrows: true,
    focusOnSelect: true,
  };
  return (
    <section
      className={styles.section_partners_carousel}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div
        className={classnames(
          styles.container_partners,
          onScreen?.isIntersecting ? styles.visible : ''
        )}
        ref={wrapperRef}
      >
        <div className={styles.partners_subtitle}>
          <PrismicRichText field={slice.primary.subtitle} />
        </div>
        <div className={styles.partners_title}>
          <PrismicRichText field={slice.primary.title} />
        </div>
        <Slider ref={slider} {...slickSettings}>
          {slice?.items.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <div className={styles.item_partner}>
                  <Image
                    src={item.logo.url}
                    alt={item.logo.alt || 'partner icon'}
                    width={item.logo.dimensions.width}
                    height={item.logo.dimensions.height}
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                    sizes="(max-width: 768px) 60px, 10.556vw"
                  />
                </div>
              </React.Fragment>
            );
          })}
        </Slider>
        <div className={styles.partners_arrows}>
          <span>
            <Image
              alt="prev arrow"
              className={styles.prev_arrow}
              src={arrowRight}
              onClick={() => slider?.current?.slickPrev()}
              style={{
                height: 'auto',
              }}
            />
          </span>
          <span>
            <Image
              alt="next arrow"
              className={styles.next_arrow}
              src={arrowRight}
              onClick={() => slider?.current?.slickNext()}
              style={{
                height: 'auto',
              }}
            />
          </span>
        </div>
      </div>
    </section>
  );
};

export default forwardRef(PartnerCarousel);
