import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import styles from '../../styles/slices/MixedContent.module.scss';
import classnames from 'classnames';
import MixedContentCard from '../../components/mixedContentCard/mixedContentCard';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

const MixedContent = ({ slice, cards }, ref) => {
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  const [filteredCards, setFilteredCards] = useState([, ,]);
  useEffect(() => {
    const tempFilteredCards = [, ,];
    cards.map((item, index) => {
      if (slice.primary.card1.id === item.id) {
        tempFilteredCards[0] = item;
      }
      if (slice.primary.card2.id === item.id) {
        tempFilteredCards[1] = item;
      }
      if (slice.primary.card3.id === item.id) {
        tempFilteredCards[2] = item;
      }
    });
    setFilteredCards(tempFilteredCards);
  }, [slice, cards]);

  return (
    <section
      id={slice.primary.uid || ''}
      ref={ref}
      className={classnames(
        styles.mixedWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
    >
      <div className={classnames(styles.mixedContent_wrapper)} ref={wrapperRef}>
        <div className={classnames(styles.mixedContent_container)}>
          <div className={classnames(styles.top_section)}>
            {slice.primary.subtitle ? (
              <div className={styles.top_subtitle}>
                <PrismicRichText field={slice.primary.subtitle} />
              </div>
            ) : null}
            {slice.primary.title ? (
              <PrismicRichText field={slice.primary.title} />
            ) : null}
            {slice.primary.description ? (
              <PrismicRichText field={slice.primary.description} />
            ) : null}
          </div>
          <div className={classnames(styles.cards_container)}>
            {filteredCards?.map((item, index) => {
              return (
                <MixedContentCard key={index} card={item}></MixedContentCard>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default forwardRef(MixedContent);
