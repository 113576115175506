import styles from '../../styles/components/MixedContentCard.module.scss';
import React, { useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

export default function MixedContentCard({ card, layout }) {
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  return (
    <div
      className={classnames(
        styles.card_container,
        layout === 'four_column' ? styles.four_column : '',
        card.data.imageoverlay.url && styles.card_big,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      ref={wrapperRef}
    >
      {card.data.image.url && !card.data.imageoverlay.url ? (
        <span className={styles.big_image}>
          <Image
            src={card.data.image.url}
            alt={card.data.image.alt || 'mixed content'}
            width={card.data.image.dimensions.width}
            height={card.data.image.dimensions.height}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </span>
      ) : card.data.imageoverlay.url ? (
        <div className={styles.card_right_overlay}>
          <Image
            src={card.data.imageoverlay.url}
            alt={card.data.imageoverlay.alt || 'overlay'}
            width={card.data.imageoverlay.dimensions.width}
            height={card.data.imageoverlay.dimensions.height}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
            sizes="(max-width: 768px) 100vw, 20.5vw"
          />
          <div>
            <Link
              href={card.data.linkCTA.url}
              target={card.data.linkCTA.target}
              className={classnames(styles.btn, styles.secondary_white)}
            >
              {card.data.linktext}
            </Link>
          </div>
        </div>
      ) : (
        <div className={styles.icons_with_text}>
          {card.data.icon.url ? (
            <span className={styles.icon_wrapper}>
              <Image
                src={card.data.icon.url}
                alt={card.data.icon.alt || 'icon'}
                width={card.data.icon.dimensions.width}
                height={card.data.icon.dimensions.height}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </span>
          ) : null}
          <div className={styles.card_subtitle}>
            <PrismicRichText field={card?.data?.subtitle} />
          </div>
          <div className={styles.card_title}>
            <PrismicRichText field={card?.data?.title} />
          </div>
          <div className={styles.card_description}>
            <PrismicRichText field={card?.data?.description} />
          </div>
        </div>
      )}
    </div>
  );
}
