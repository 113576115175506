import React, { useRef, useContext, forwardRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import styles from '../../styles/slices/FeaturesCarousel.module.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import classnames from 'classnames';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { MobileContext } from '../../context/mobileContext';
import Image from 'next/image';
import rightArrow from '../../public/arrow-right-white.svg';

const FeaturesCarousel = ({ slice }, ref) => {
  const slider = useRef(null);
  const isMobile = useContext(MobileContext);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);

  let slickSettings = {
    className: 'carouselFeatures',
    dots: true,
    infinite: false,
    speed: 300,
    variableWidth: true,
    arrows: false,
    focusOnSelect: true,
    touchThreshold: 400000,
  };
  const slickArrows = (
    <div className={styles.features_arrows}>
      <span>
        <Image
          alt="prev arrow"
          className={styles.prev_arrow}
          src={rightArrow}
          onClick={() => slider?.current?.slickPrev()}
        />
      </span>
      <span>
        <Image
          alt="next arrow"
          className={styles.next_arrow}
          src={rightArrow}
          onClick={() => slider?.current?.slickNext()}
        />
      </span>
    </div>
  );
  return (
    <section
      className={classnames(
        styles.featuresCarouselWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <span className={styles.featuresSubtitle} ref={wrapperRef}>
        <PrismicRichText field={slice.primary.subtitle} />
      </span>
      <span className={styles.featuresTitle}>
        <PrismicRichText field={slice.primary.title} />
      </span>
      <div className={styles.carouselWrapper}>
        {slice.items.length < 4 && !isMobile ? (
          slice.items.map((item, index) => {
            return (
              <div key={index}>
                <div className={styles.carouselItem}>
                  <span className={styles.itemTitle}>
                    <PrismicRichText field={item.title} />
                  </span>
                  <span className={styles.itemDescription}>
                    <PrismicRichText field={item.description} />
                  </span>
                </div>
              </div>
            );
          })
        ) : (
          <Slider ref={slider} {...slickSettings}>
            {slice.items.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div>
                    <div className={styles.carouselItem}>
                      <span className={styles.itemTitle}>
                        <PrismicRichText field={item.title} />
                      </span>
                      <span className={styles.itemDescription}>
                        <PrismicRichText field={item.description} />
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </Slider>
        )}
      </div>
      {slice.items.length > 3
        ? slickArrows
        : slice.items.length > 1 && isMobile
        ? slickArrows
        : null}
    </section>
  );
};

export default forwardRef(FeaturesCarousel);
