import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import styles from '../../styles/slices/ExpandableProfile.module.scss';
import Filters from '../../components/filters/filters';
import ProfileCategory from '../../components/profileCategory/profileCategory';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import classnames from 'classnames';

const ExpandableProfile = ({ slice, categories }, ref) => {
  const [filters, setFilters] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);

  const updateFilter = (filter) => {
    setActiveFilter(filter);
  };

  useEffect(() => {
    const filterTempArray = [];
    slice.items.map((item) => {
      filterTempArray.push({
        value: item.category.uid,
        label: item.category.uid,
      });
    });
    const filterArray = filterTempArray.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (item) => item.value === value.value && item.label === value.label
        )
    );
    setFilters(filterArray);
    setActiveFilter(filterArray[0].value);
  }, [slice]);

  return (
    <section
      className={classnames(
        styles.expandableProfileWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      ref={ref}
      id={slice.primary.uid || ''}
    >
      <div className={styles.container_wrapper} ref={wrapperRef}>
        <div className={styles.subtitle}>
          <PrismicRichText field={slice.primary.subtitle} />
        </div>
        <div className={styles.title}>
          <PrismicRichText field={slice.primary.title} />
        </div>

        <Filters
          filters={filters}
          updateFn={updateFilter}
          activeFilter={activeFilter}
        ></Filters>

        {slice.items.map((item, index) => {
          const category = categories.find((element) => {
            return element.id === item.category.id;
          });
          return (
            category.uid === activeFilter && (
              <ProfileCategory
                key={index}
                category={category}
              ></ProfileCategory>
            )
          );
        })}
      </div>
    </section>
  );
};

export default forwardRef(ExpandableProfile);
