import React, { forwardRef, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import styles from '../../styles/slices/TwoColumnText.module.scss';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import classnames from 'classnames';

const TwoColumnText = ({ slice }, ref) => {
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  return (
    <section
      className={classnames(
        styles.section,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div className={styles.titleWrapper} ref={wrapperRef}>
        <span className={styles.subtitle}>
          {slice.primary.subtitle ? (
            <PrismicRichText field={slice.primary.subtitle} />
          ) : null}
        </span>
        <span className={styles.title}>
          {slice.primary.title ? (
            <PrismicRichText field={slice.primary.title} />
          ) : null}
        </span>
      </div>
      <div className={styles.textWrapper}>
        {slice.primary.textarea ? (
          <PrismicRichText field={slice.primary.textarea} />
        ) : null}
      </div>
    </section>
  );
};

export default forwardRef(TwoColumnText);
