import styles from '../../styles/components/Filters.module.scss';
import React, { useContext, useRef } from 'react';
import Select, { components } from 'react-select';
import { MobileContext } from '../../context/mobileContext';
import classnames from 'classnames';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

export default function Filters({ filters, updateFn, activeFilter }) {
  const isMobile = useContext(MobileContext);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  const filterStyles = {
    menu: () => ({
      margin: 0,
      boxShadow: '0px 48px 48px 0px #0E0B350A',
    }),
    menuList: () => ({
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (provided, state) => ({
      color: state.isSelected ? '#e80c26' : '#191B25',
      padding: 16,
      background: '#FFFFFF',
    }),
    valueContainer: () => ({
      display: 'flex',
      alignItems: 'center',
    }),
    control: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      borderRadius: state.selectProps.menuIsOpen ? '4px 4px 0 0' : '4px',
      background: '#F9F7F9',
      padding: '12px 16px',
      boxShadow: 'box-shadow: inset 2px 0px 8px 1.5px rgba(31, 25, 101, 0.04);',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#E80C26',
      transition: 'all .3s ease-out',
      transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : null,
    }),
    singleValue: () => ({
      margin: '0px',
      padding: '0px',
      color: '#756f85',
    }),
  };
  return (
    <div
      className={classnames(
        styles.filtersWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      ref={wrapperRef}
    >
      {!isMobile && (
        <ul>
          {filters.map((item, index) => {
            return (
              <li
                className={item.value === activeFilter ? styles.active : ''}
                key={index}
                onClick={() => updateFn(item.value)}
              >
                {item.value}
              </li>
            );
          })}
        </ul>
      )}
      {isMobile && (
        <>
          <p>Select category</p>
          <Select
            aria-label="filter category dropdown"
            onChange={(e) => {
              updateFn(e.value);
            }}
            defaultValue={{ value: activeFilter, label: activeFilter }}
            value={{ value: activeFilter, label: activeFilter }}
            options={filters}
            className={`${styles.filterSelect}`}
            classNamePrefix={`${styles.filterSelect}`}
            styles={filterStyles}
            isSearchable={false}
            components={{
              Menu: (props) => (
                <components.Menu {...props} className={styles.menu} />
              ),
            }}
          ></Select>
        </>
      )}
    </div>
  );
}
