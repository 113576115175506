import Image from 'next/image';
import React from 'react';
import styles from '../../styles/components/V2GCalculatorRadio.module.scss';

function V2GCalculatorRadio({ selectedType, setSelectedType }) {
  return (
    <div className={styles.radioGroup}>
      <label>
        <input
          type="radio"
          id="leadtype"
          name="What type of user are you"
          value="business"
          checked={selectedType === 'business'}
          onChange={(e) => setSelectedType(e.target.value)}
        />
        <div
          className={`${styles.imageWrapper} ${styles.radioOption} ${
            selectedType === 'business' ? styles.selected : ''
          }`}
        >
          <Image
            width={200}
            height={200}
            src="/v2g-option-1.png"
            alt="Business"
          />
          <span>Business</span>
        </div>
      </label>

      <label>
        <input
          id="leadtype"
          name="What type of user are you"
          type="radio"
          value="school"
          checked={selectedType === 'school'}
          onChange={(e) => setSelectedType(e.target.value)}
        />
        <div
          className={`${styles.imageWrapper} ${styles.radioOption} ${
            selectedType === 'school' ? styles.selected : ''
          }`}
        >
          <Image
            width={200}
            height={200}
            src="/v2g-option-2.png"
            alt="School Bus"
          />
          <span>School bus fleet</span>
        </div>
      </label>

      <label>
        <input
          id="leadtype"
          name="What type of user are you"
          type="radio"
          value="residential"
          checked={selectedType === 'residential'}
          onChange={(e) => setSelectedType(e.target.value)}
        />
        <div
          className={`${styles.imageWrapper} ${styles.radioOption} ${
            selectedType === 'residential' ? styles.selected : ''
          }`}
        >
          <Image
            width={200}
            height={200}
            src="/v2g-option-3.png"
            alt="Residential"
          />
          <span>Residential</span>
        </div>
      </label>
    </div>
  );
}

export default V2GCalculatorRadio;
