import React, { forwardRef, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import Link from 'next/link';
import MixedContentCard from '../../components/mixedContentCard/mixedContentCard';
import styles from '../../styles/slices/ThreeColumnStatCards.module.scss';
import classnames from 'classnames';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

const ThreeColumnStatCards = ({ slice, cards }, ref) => {
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  return (
    <section
      ref={ref}
      className={classnames(
        styles.threeColumnStatCardsWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={slice.primary.uid || ''}
    >
      <div className={styles.textWrapper} ref={wrapperRef}>
        <span className={styles.subtitle}>
          {slice.primary.subtitle ? (
            <PrismicRichText field={slice.primary.subtitle} />
          ) : null}
        </span>
        <span className={styles.title}>
          {slice.primary.title ? (
            <PrismicRichText field={slice.primary.title} />
          ) : null}
        </span>
      </div>
      <div className={styles.btnWrapper}>
        {slice.primary.buttonlink.url ? (
          <Link
            href={slice.primary.buttonlink.url}
            target={slice.primary.buttonlink.target}
            className={classnames(styles.btn, styles.secondary_white)}
          >
            {slice.primary.buttontext}
          </Link>
        ) : null}
      </div>
      <div className={styles.cardsWrapper}>
        {slice.items.map((item, index) => {
          if (index < 3) {
            const card = cards.find((element) => element.id === item.cards.id);
            return (
              <MixedContentCard key={index} card={card}></MixedContentCard>
            );
          }
        })}
      </div>
    </section>
  );
};

export default forwardRef(ThreeColumnStatCards);
