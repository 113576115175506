import classnames from 'classnames';
import Script from 'next/script';
import React, { forwardRef, useEffect, useState } from 'react';
import V2GCalculatorRadio from '../../components/v2gCalculatorRadio';
import V2GCalculatorSlider from '../../components/v2gCalculatorSlider';
import styles from '../../styles/slices/V2GCalculator.module.scss';
import v2gCalcValues from '../../utils/v2gCalcValues';

const V2GCalculator = ({ slice }, ref) => {
  const [v2gResult, setV2GResult] = useState(0);
  const [selectedType, setSelectedType] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [chargers, setChargers] = useState(1);
  const [maxVal, setMaxVal] = useState(5);
  const [readMoreActive, setReadMoreActive] = useState(false);

  useEffect(() => {
    const timestamp = () => {
      const response = document.getElementById('g-recaptcha-response');
      if (response == null || response.value.trim() == '') {
        const elems = JSON.parse(
          document.getElementsByName('captcha_settings')[0].value
        );
        elems['ts'] = JSON.stringify(new Date().getTime());
        document.getElementsByName('captcha_settings')[0].value =
          JSON.stringify(elems);
      }
    };

    setInterval(timestamp, 500);
  }, []);

  useEffect(() => {
    if (selectedType) {
      setMaxVal(selectedType === 'residential' ? 5 : 10);
    }

    if (selectedType && selectedState) {
      const value = v2gCalcValues?.[selectedState]?.[selectedType];
      const resultPerCharger = value * chargers;
      const result = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(resultPerCharger);
      setV2GResult(result);
    }
  }, [selectedType, selectedState, chargers]);

  return (
    <>
      <Script id="recaptcha" src="https://www.google.com/recaptcha/api.js" />
      <section
        className={styles.calculatorSection}
        id={slice.primary.uid || ''}
        ref={ref}
      >
        <form
          action="/api/contact"
          name="v2gCalculatorForm"
          method="post"
          className={styles.calculatorForm}
        >
          <div className={styles.calculatorFormWrapper}>
            <div className={styles.calculatorTitles}>
              <p>V2G CALCULATOR</p>
              <h4>How much can V2G earn for you?</h4>
            </div>
            <input
              type="hidden"
              name="captcha_settings"
              value='{"keyname":"FE_Website_SetupByJimmy","fallback":"true","orgId":"00D2E000001FNry","ts":""}'
            />
            <input type="hidden" name="oid" value="00D2E000001FNry" />

            <input type="hidden" name="_clientID" value="546004065" />
            <input
              type="hidden"
              name="_deExternalKey"
              value="AAD56538-1C58-4E2F-888B-75BCE551BD32"
            />
            <input type="hidden" name="_action" value="add" />
            <input type="hidden" name="_returnXML" value="0" />
            <input
              type="hidden"
              name="_successURL"
              value={`${process.env.NEXT_PUBLIC_BASE_URL}contact?state=success`}
            />
            <input
              type="hidden"
              name="_errorURL"
              value={`${process.env.NEXT_PUBLIC_BASE_URL}contact?state=error`}
            />

            <div className={classnames(styles.formRow, styles.radioRow)}>
              <div className={styles.formItem}>
                <p className={styles.step}>1. What type of user are you?</p>
                <V2GCalculatorRadio
                  selectedType={selectedType}
                  setSelectedType={setSelectedType}
                />
              </div>
            </div>

            <div className={styles.formRow}>
              <div className={styles.formItem}>
                <p className={styles.step}>2. State</p>
                <select
                  id="us_state"
                  name="State"
                  title="State"
                  required
                  onChange={(e) => setSelectedState(e.target.value)}
                >
                  <option value="">Select a state</option>
                  {Object.keys(v2gCalcValues).map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={classnames(styles.formRow, styles.sliderRow)}>
              <div className={classnames(styles.formItem, styles.full)}>
                <p className={styles.step}>3. Number of V2G chargers</p>
                <V2GCalculatorSlider
                  chargers={chargers}
                  setChargers={setChargers}
                  maxVal={maxVal}
                />
              </div>
            </div>
          </div>
          <div className={styles.calculatorSubmitContainer}>
            <div className={styles.calculatorSubmitWrapper}>
              <h3>Your V2G value estimate:</h3>
              <div>
                <div className={styles.resultsWrapper}>
                  <p>V2G value, USD/year</p>
                  <p>{v2gResult === 0 ? `$0` : v2gResult}</p>
                </div>
                <p className={styles.disclaimer}>
                  This estimate is indicative only and is not meant to be relied
                  upon for investment decisions or contractual agreements.
                  <span
                    onClick={() => setReadMoreActive(true)}
                    style={{
                      display: readMoreActive ? 'none' : 'inline',
                    }}
                  >
                    ..
                  </span>{' '}
                  <span
                    onClick={() => setReadMoreActive(true)}
                    style={{
                      display: readMoreActive ? 'none' : 'inline',
                      color: '#0072ce',
                      cursor: 'pointer',
                    }}
                  >
                    Read more
                  </span>
                  <span style={{ display: readMoreActive ? 'inline' : 'none' }}>
                    It is based on assumptions on electricity rates, energy
                    market prices, utility programs, your vehicle’s
                    availability, and charging power. Additional incentives may
                    be available. A more accurate estimate for your individual
                    case is available upon request.
                  </span>
                </p>
              </div>
              <div className={styles.formRow}>
                <div className={styles.formItem}>
                  <label htmlFor="name">First Name</label>
                  <input
                    id="name"
                    maxLength="40"
                    name="FirstName"
                    size="20"
                    type="text"
                    placeholder="John"
                    required
                  />
                </div>
                <div className={styles.formItem}>
                  <label htmlFor="name">Last Name</label>
                  <input
                    id="name"
                    maxLength="40"
                    name="LastName"
                    size="20"
                    type="text"
                    placeholder="Smith"
                    required
                  />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.formItem}>
                  <label htmlFor="email">Email address</label>
                  <input
                    id="email"
                    maxLength="80"
                    name="Email"
                    size="20"
                    type="text"
                    placeholder="John@gmail.com"
                    required
                  />
                </div>
              </div>
              <div className={styles.formRow}>
                <div
                  className="g-recaptcha"
                  data-sitekey="6Lc8HT8jAAAAAC4jBH7qCIGLKJp2Jg-clSAKirNj"
                ></div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.submit_container}>
                  <input
                    className={styles.submit_btn}
                    type="submit"
                    name="submit"
                    value="Get more information"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default forwardRef(V2GCalculator);
