import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { PrismicRichText } from '@prismicio/react';
import Link from 'next/link';
import styles from '../../styles/slices/NavigationSubMenu.module.scss';
import Image from 'next/image'

const NavigationSubMenu = ({ slice }) => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1);
    }, 0);
    return () => {
      setOpacity(0);
    };
  }, []);

  return (
    <section
      className={styles.navigationSubmenuWrapper}
      style={{ opacity: opacity }}
    >
      <div className={styles.sub_menu_container_small}>
        <div className={styles.sub_menu_left}>
          <PrismicRichText field={slice.data.slices[0].primary.title} />
          <PrismicRichText field={slice.data.slices[0].primary.description} />
        </div>
        <ul className={styles.sub_menu_center}>
          {slice.data.slices[0].items.map((item, index) => (
            <li key={index}>
              <Link href={item.link.url} target={item.link.target}>
                {item.linktitle}
              </Link>
            </li>
          ))}
        </ul>
        <div className={styles.sub_menu_right}>
          <p>
            <Image
              src={slice.data.slices[0].primary.downloadbackground.url}
              alt={
                slice.data.slices[0].primary.downloadbackground.alt ||
                'download background'
              }
              width={
                slice.data.slices[0].primary.downloadbackground.dimensions.width
              }
              height={
                slice.data.slices[0].primary.downloadbackground.dimensions
                  .height
              }
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
              }}
            />
            <Link
              href={slice.data.slices[0].primary.downloadlink.url}
              target={slice.data.slices[0].primary.downloadlink.target}
            >
              {slice.data.slices[0].primary.downloadbuttontext}
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default NavigationSubMenu;
