const v2gCalcValues = {
  California: {
    business: 2875,
    residential: 1017,
    school: 3552,
  },
  'New York': {
    business: 3612,
    residential: 2709,
    school: 7224,
  },
  'Rhode Island': {
    business: 4774,
    residential: 2400,
    school: 9547,
  },
  Connecticut: {
    business: 3962,
    residential: 1200,
    school: 7924,
  },
  Massachusetts: {
    business: 4837,
    residential: 1650,
    school: 9675,
  },
  Colorado: {
    business: 3122,
    residential: 0,
    school: 1041,
  },
  Texas: {
    business: 1528,
    residential: 1560,
    school: 4305,
  },
  'Other US': {
    business: 2309,
    residential: 977,
    school: 3239,
  },
  International: {
    business: 869,
    residential: 977,
    school: 2519,
  },
};

export default v2gCalcValues;
