import Vimeo from '@u-wave/react-vimeo';
import Image from 'next/image';
import classnames from 'classnames';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import playIcon from '../../public/play_testimonials.svg';
import 'yet-another-react-lightbox/styles.css';
import styles from '../../styles/slices/VideoBlock.module.scss';
import Lightbox from 'yet-another-react-lightbox';
import { PrismicRichText } from '@prismicio/react';

/**
 * @typedef {import("@prismicio/client").Content.VideoBlockSlice} VideoBlockSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<VideoBlockSlice>} VideoBlockProps
 * @param {VideoBlockProps}
 */
const VideoBlock = ({ slice }, ref) => {
  const [playVimeo, setPlayVimeo] = useState(false);
  const [open, setOpen] = useState(false);

  function isBrowser() {
    return typeof window !== 'undefined';
  }

  const getDimensions = useCallback(() => {
    if (!isBrowser()) return { width: 0, height: 0 };

    const viewportWidth = window.innerWidth;
    const width = viewportWidth >= 768 ? viewportWidth * 0.75 : viewportWidth;

    return {
      width,
      height: window.innerHeight,
    };
  }, []);

  const [dimensions, setDimensions] = useState(getDimensions());

  useEffect(() => {
    if (!isBrowser()) return;

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getDimensions]);

  const PauseVideos = () => {
    setPlayVimeo(true);
  };

  return (
    <section
      className={classnames(
        styles.videoBlockSection,
        slice.primary.imagestyle === 'full-width'
          ? styles.fullWidth
          : styles.halfWidth
      )}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div className={styles.videoTitle}>
        <PrismicRichText field={slice.primary.video_title} />
      </div>
      <div className={styles.image}>
        <Image
          src={slice.primary.background.url}
          alt={slice.primary.background.alt || 'video block'}
          width={slice.primary.background.dimensions.width}
          height={slice.primary.background.dimensions.height}
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
            aspectRatio: 16 / 9,
          }}
          priority={true}
          sizes="(max-width: 768px) 100vw, 50vw"
        />

        {slice.primary?.vimeo_video?.url ? (
          <div className={styles.videoWrapper}>
            <div
              className={styles.imagePlay}
              onClick={() => {
                setOpen(true);
                setPlayVimeo(true);
              }}
            >
              <div className={styles.imagePlayWrapper}>
                <Image src={playIcon} alt="Play" />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {slice.primary?.vimeo_video?.url ? (
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          slides={[
            {
              type: 'custom-slide',
            },
          ]}
          carousel={{
            finite: true,
          }}
          styles={{ root: { '--yarl__color_backdrop': 'rgba(0, 0, 0, .8)' } }}
          controller={{ closeOnBackdropClick: true }}
          render={{
            slide: ({ slide }) =>
              slide.type === 'custom-slide' ? (
                <>
                  <Vimeo
                    video={slice.primary?.vimeo_video?.url}
                    paused={!playVimeo}
                    onPause={PauseVideos}
                    autoplay={playVimeo}
                    width={dimensions.width}
                    height={dimensions.height}
                  />
                </>
              ) : undefined,
            buttonPrev: () => null,
            buttonNext: () => null,
          }}
        />
      ) : null}
    </section>
  );
};

export default forwardRef(VideoBlock);
