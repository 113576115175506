import React, { forwardRef, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';
import Image from 'next/image';
import styles from '../../styles/slices/Awards.module.scss';
import Link from 'next/link';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
/**
 * @typedef {import("@prismicio/client").Content.AwardsSlice} AwardsSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<AwardsSlice>} AwardsProps
 * @param {AwardsProps}
 */
const Awards = ({ slice }, ref) => {
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={classnames(
        styles.section,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div className={styles.content} ref={wrapperRef}>
        <span className={styles.title}>
          {slice.primary.title ? (
            <PrismicRichText field={slice.primary.title} />
          ) : null}
        </span>
        <div className={styles.description}>
          {slice.primary.description ? (
            <PrismicRichText field={slice.primary.description} />
          ) : null}
        </div>
      </div>
      {Array.isArray(slice.items) && slice.items.length > 0 && (
        <div className={styles.imagesWrapper}>
          {slice.items.map((award) => (
            <div className={styles.image_container}>
              <Link href={award.awardUrl.url}>
                <Image
                  src={award.awardImage.url}
                  alt={award.awardImage.alt || 'column'}
                  width={award.awardImage.dimensions.width}
                  height={award.awardImage.dimensions.height}
                  sizes="(max-width: 768px) 100vw, 28.889vw"
                />
              </Link>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default forwardRef(Awards);
