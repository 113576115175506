import * as prismic from '@prismicio/client';
import classnames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { createClient } from '../../prismicio';
import styles from '../../styles/slices/SearchResults.module.scss';

const SearchResults = ({ slice }, ref) => {
  const router = useRouter();
  const [results, setResults] = useState([]);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);

  const search = async (term) => {
    const client = createClient();
    const pages = await client.get({
      filters: [
        prismic.filter.any('document.type', [
          'page',
          'article',
          'casestudy',
          'contactpage',
        ]),
        prismic.filter.fulltext('document', term),
      ],
    });
    setResults(pages.results);
  };

  useEffect(() => {
    if (router.query.term) {
      search(router.query.term);
    }
  }, [router.query.term]);

  return (
    <section
      className={classnames(
        styles.searchResultsWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      ref={ref}
    >
      <div className={styles.searchResultsHero} ref={wrapperRef}>
        <div className={styles.searchResultsText}>
          <p className={styles.subtitle}>SEARCH RESULTS</p>
          <p className={styles.title}>{router.query.term}</p>
          <p className={styles.text}>
            We found {results.length} results for "{router.query.term}"
          </p>
        </div>
      </div>
      <div className={styles.resultsWrapper}>
        {results.map((item, index) => {
          return (
            <div className={styles.result} key={index}>
              <Link href={item.url} passHref>
                <div className={styles.resultTextWrapper}>
                  <p className={styles.resultTitle}>{item.data.title}</p>
                  <p className={styles.resultDescription}>
                    {item.data.metaDescription}
                  </p>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default forwardRef(SearchResults);
