import React, { forwardRef, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import Image from 'next/image';
import styles from '../../styles/slices/Benefits.module.scss';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import classnames from 'classnames';

const Benefits = ({ slice }, ref) => {
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  return (
    <section
      className={classnames(
        styles.benefitsWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div className={styles.textWrapper} ref={wrapperRef}>
        {slice.primary.subtitle ? (
          <span className={styles.subtitle}>
            <PrismicRichText field={slice.primary.subtitle} />
          </span>
        ) : null}
        {slice.primary.title ? (
          <span className={styles.title}>
            <PrismicRichText field={slice.primary.title} />
          </span>
        ) : null}
        {slice.primary.description ? (
          <span className={styles.description}>
            <PrismicRichText field={slice.primary.description} />
          </span>
        ) : null}
      </div>
      <div className={styles.cardsWrapper}>
        {slice.items.map((item, index) => {
          return (
            <div className={styles.benefitsItem} key={index}>
              <div className={styles.iconWrapper}>
                <Image
                  src={item.icon.url}
                  alt={item.icon.alt || 'benefits'}
                  width={item.icon.dimensions.width}
                  height={item.icon.dimensions.height}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </div>
              <PrismicRichText field={item.description} />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default forwardRef(Benefits);
