import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';
import Script from 'next/script';
import React, { forwardRef, useEffect } from 'react';
import styles from '../../styles/slices/ContactForm.module.scss';
import states from '../../utils/states';

const ContactForm = ({ slice, success }, ref) => {
  useEffect(() => {
    const timestamp = () => {
      const response = document.getElementById('g-recaptcha-response');
      if (response == null || response.value.trim() == '') {
        const elems = JSON.parse(
          document.getElementsByName('captcha_settings')[0].value
        );
        elems['ts'] = JSON.stringify(new Date().getTime());
        document.getElementsByName('captcha_settings')[0].value =
          JSON.stringify(elems);
      }
    };

    let interval;
    if (!success) {
      interval = setInterval(timestamp, 500);
    } else {
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [success]);

  return (
    <>
      {!success && (
        <Script id="recaptcha" src="https://www.google.com/recaptcha/api.js" />
      )}
      <section
        className={styles.contactFormWrapper}
        id={slice.primary.uid || ''}
        ref={ref}
      >
        {!success && (
          <>
            {slice.primary.title ? (
              <div className={styles.form_title}>
                <PrismicRichText field={slice.primary.title} />
              </div>
            ) : null}
            {slice.primary.description ? (
              <div className={styles.form_description}>
                <PrismicRichText field={slice.primary.description} />
              </div>
            ) : null}

            <form action="/api/contact" name="contactForm" method="post">
              <input
                type="hidden"
                name="captcha_settings"
                value='{"keyname":"FE_Website_SetupByJimmy","fallback":"true","orgId":"00D2E000001FNry","ts":""}'
              />
              <input type="hidden" name="oid" value="00D2E000001FNry" />

              <input type="hidden" name="_clientID" value="546004065" />
              <input
                type="hidden"
                name="_deExternalKey"
                value="F23F5AD5-B494-42D8-BF12-B70D4C8F77ED"
              />
              <input type="hidden" name="_action" value="add" />
              <input type="hidden" name="_returnXML" value="0" />
              <input
                type="hidden"
                name="_successURL"
                value={`${process.env.NEXT_PUBLIC_BASE_URL}contact?state=success`}
              />
              <input
                type="hidden"
                name="_errorURL"
                value={`${process.env.NEXT_PUBLIC_BASE_URL}contact?state=error`}
              />

              {/* {process.env.NODE_ENV === 'development' && (
                <>
                  <input type="hidden" name="debug" value="1" />
                  <input
                    type="hidden"
                    name="debugEmail"
                    value="jesh@fermataenergy.com"
                  />
                </>
              )} */}
              <div className={styles.formRow}>
                <div className={styles.formItem}>
                  <label htmlFor="first_name">
                    {slice.primary.firstnamelabel}
                  </label>
                  <input
                    id="first_name"
                    maxLength="40"
                    name="FirstName"
                    size="20"
                    type="text"
                    placeholder={slice.primary.firstnameplaceholder}
                    required
                  />
                </div>
                <div className={styles.formItem}>
                  <label htmlFor="last_name">
                    {slice.primary.lastnamelabel}
                  </label>
                  <input
                    id="last_name"
                    maxLength="80"
                    name="LastName"
                    size="20"
                    type="text"
                    placeholder={slice.primary.lastnameplaceholder}
                    required
                  />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.formItem}>
                  <label htmlFor="email">{slice.primary.emaillabel}</label>
                  <input
                    id="email"
                    maxLength="80"
                    name="EmailAddress"
                    size="20"
                    type="text"
                    placeholder={slice.primary.emailplaceholder}
                    required
                  />
                </div>
                <div className={styles.formItem}>
                  <span className={styles.select_label}>
                    {slice.primary?.statelabel || 'State*'}
                  </span>
                  <select id="us_state" name="State" title="State" required>
                    <option value="">
                      {slice.primary?.stateplaceholder || 'Select a state'}
                    </option>
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.formItem}>
                  <label htmlFor="phone">{slice.primary.phonelabel}</label>
                  <input
                    id="phone"
                    maxLength="40"
                    name="Phone"
                    size="20"
                    type="text"
                  />
                </div>
                <div className={styles.formItem}>
                  <label htmlFor="company">{slice.primary.companylabel}</label>
                  <input
                    id="company"
                    maxLength="40"
                    name="Company"
                    size="20"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={classnames(styles.formItem, styles.full)}>
                  <span className={styles.select_label}>
                    {slice.primary.dropdownlabel}
                  </span>
                  <select
                    id="leadtype"
                    name="How can we help you"
                    title="Lead Type"
                    required
                  >
                    <option value="">
                      {slice.primary.dropdownplaceholder}
                    </option>
                    <option value="Commercial Customer">
                      Commercial Customer
                    </option>
                    <option value="Residential Customer">
                      Residential Customer
                    </option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={classnames(styles.formItem, styles.full)}>
                  <label htmlFor="message">{slice.primary.textarealabel}</label>
                  <textarea
                    id="message"
                    name="Your message"
                    rows="3"
                    type="text"
                    wrap="soft"
                    required
                  ></textarea>
                </div>
              </div>
              <div className={styles.formRow}>
                <div
                  className="g-recaptcha"
                  data-sitekey="6Lc8HT8jAAAAAC4jBH7qCIGLKJp2Jg-clSAKirNj"
                ></div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.submit_container}>
                  <input
                    className={styles.submit_btn}
                    type="submit"
                    name="submit"
                  />
                </div>
              </div>
            </form>
          </>
        )}
        {success && (
          <>
            {slice.primary.formsuccesstext ? (
              <div className={styles.form_title}>
                <PrismicRichText field={slice.primary.formsuccesstext} />
              </div>
            ) : null}
          </>
        )}
      </section>
    </>
  );
};

export default forwardRef(ContactForm);
