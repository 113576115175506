import { forwardRef } from 'react';
import Image from 'next/image';
import styles from '../../styles/slices/PartnersGrid.module.scss';
import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';

const PartnersGrid = ({ slice }, ref) => {
  const logos = slice.items;

  // Split logos into two rows
  const firstRow = [];
  const secondRow = [];

  logos.forEach((logo, index) => {
    if (index % 2 !== 0) {
      firstRow.push(logo);
    } else {
      secondRow.push(logo);
    }
  });

  return (
    <div
      id={slice.primary.uid || ''}
      ref={ref}
      className={styles.partnersLogosContainer}
    >
      <div className={styles.partners_title}>
        <PrismicRichText field={slice.primary.title} />
      </div>
      <div className={styles.logoGridContainer}>
        {/* First Row */}
        <div className={styles.logoGrid}>
          {firstRow.map((item) => (
            <div key={item.partner_logo.id} className={styles.logoItem}>
              <Image
                src={item.partner_logo.url}
                alt={item.partner_logo.alt}
                fill
                style={{ objectFit: 'contain' }}
              />
            </div>
          ))}
        </div>
        {/* Second Row */}
        <div className={styles.logoGrid}>
          {secondRow.map((item) => (
            <div key={item.partner_logo.id} className={styles.logoItem}>
              <Image
                src={item.partner_logo.url}
                alt={item.partner_logo.alt}
                fill
                style={{ objectFit: 'contain' }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(PartnersGrid);
