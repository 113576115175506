import React, { useState } from 'react';
import { PrismicRichText } from '@prismicio/react';
import Image from 'next/image';
import Link from 'next/link';
import classnames from 'classnames';
import styles from '../../styles/slices/InternalHero.module.scss';
import { forwardRef } from 'react';
import VideoBackground from '../../components/videoBackground/videoBackground';
import Lightbox from 'yet-another-react-lightbox';
import useSetupVideo from '../../hooks/useSetupVideo';
import Vimeo from '@u-wave/react-vimeo';

const InternalHero = ({ slice }, ref) => {

  const [openVideo, setOpenVideo] = useState(false);
  const [playVimeo, setPlayVimeo] = useState(false);
  const { dimensions } = useSetupVideo();

  const isVideoHero =
    slice.variation === 'videoHero' && slice.primary.backgroundvideourl.url;
  const videoCode =
    isVideoHero && slice.primary.backgroundvideourl.url.split('/').pop();
  const backgroundvideoUrl =
    videoCode &&
    `https://player.vimeo.com/video/${videoCode}?h=37bf046318&title=0&byline=0&portrait=0&background=1&autopause=0`;

  return (
    <>
      <section
        className={classnames(
          styles.mainSection,
          isVideoHero && styles.videoHero
        )}
      >
        {isVideoHero && videoCode ? (
          <VideoBackground
            styles={styles}
            videoUrl={backgroundvideoUrl}
            placeholderImage={slice.primary.backgroundvideoplaceholder}
          />
        ) : (
          <div
            className={classnames(
              styles.imageWrapper,
              slice.primary.description?.[0]?.text ? styles.biggerWrapper : null
            )}
            id={slice.primary.uid || ''}
            ref={ref}
          >
            <Image
              src={slice.primary.background.url}
              alt={slice.primary.background.alt || 'internal hero'}
              width={slice.primary.background.dimensions.width}
              height={slice.primary.background.dimensions.height}
              style={{
                width: '100%',
                height: slice.primary.longtitle ? '100%' : 'auto',
                objectFit: 'cover',
              }}
              // Bypass the loader to avoid the image being pixelated on mobile
              loader={({ src }) => src}
              priority={true}
            />
          </div>
        )}
        <div className={styles.contentWrapper}>
          <div className={styles.textWrapper}>
            <span className={styles.subtitle}>
              {slice.primary.date ? (
                <PrismicRichText field={slice.primary.date} />
              ) : slice.primary.subtitle ? (
                <PrismicRichText field={slice.primary.subtitle} />
              ) : null}
            </span>
            <span className={styles.title}>
              {slice.primary.title ? (
                <PrismicRichText field={slice.primary.title} />
              ) : null}
            </span>
            {slice.primary.description ? (
              <span className={styles.description}>
                <PrismicRichText field={slice.primary.description} />
              </span>
            ) : null}
          </div>
          {slice.primary.addbuttons ? (
            <div className={styles.btnWrapper}>
              {slice.primary.primarybuttonlink &&
                slice.primary.primarybuttontext && (
                  <Link href={slice.primary.primarybuttonlink.url}>
                    <button
                      name="hero link"
                      className={classnames(styles.btn, styles.primaryBtn)}
                    >
                      {slice.primary.primarybuttontext}
                    </button>
                  </Link>
                )}
              {slice.primary.backgroundvideourl.url &&
                slice.primary.playVideobuttontext && (
                  <button
                    name="hero video link"
                    className={classnames(styles.btn, styles.secondaryBtn)}
                    onClick={() => {
                      setOpenVideo(true);
                      setPlayVimeo(true);
                    }}
                  >
                    {slice.primary.playVideobuttontext}
                  </button>
                )}
            </div>
          ) : null}
        </div>
        {slice.primary.playbackvideourl?.url ? (
          <Lightbox
            open={openVideo}
            close={() => setOpenVideo(false)}
            slides={[
              {
                type: 'custom-slide',
              },
            ]}
            carousel={{
              finite: true,
            }}
            styles={{ root: { '--yarl__color_backdrop': 'rgba(0, 0, 0, .8)' } }}
            controller={{ closeOnBackdropClick: true }}
            render={{
              slide: ({ slide }) =>
                slide.type === 'custom-slide' ? (
                  <>
                    <Vimeo
                      video={slice.primary.playbackvideourl.url}
                      paused={!playVimeo}
                      onPause={() => setPlayVimeo(true)}
                      autoplay={playVimeo}
                      width={dimensions.width}
                      height={dimensions.height}
                    />
                  </>
                ) : undefined,
              buttonPrev: () => null,
              buttonNext: () => null,
            }}
          />
        ) : null}
      </section>
      {isVideoHero && Array.isArray(slice.items) && slice.items.length > 0 && (
        <section
          className={classnames(styles.heroSection)}
          id={slice.primary.uid || ''}
          ref={ref}
        >
          <div className={classnames(styles.featuresWrapper)}>
            {slice.items.map((elem, index) => (
              <Link key={index} href={`${elem.featuredlinkcta?.url}`} passHref>
                <div className={styles.featuresContainer}>
                  <div className={styles.featuresContent}>
                    {elem.featuredlogo?.url ? (
                      <div className={styles.featureIcon}>
                        <Image
                          src={elem.featuredlogo.url}
                          alt={elem.featuredlogo.alt || 'featured logo'}
                          width={elem.featuredlogo.dimensions.width}
                          height={elem.featuredlogo.dimensions.height}
                          style={{
                            maxWidth: '100%',
                            height: 'auto',
                          }}
                        />
                      </div>
                    ) : null}
                    {elem.featuredsubtitle && elem.featuredtitle ? (
                      <div className={styles.featuresTitle}>
                        <PrismicRichText field={elem.featuredsubtitle} />
                        <PrismicRichText field={elem.featuredtitle} />
                      </div>
                    ) : null}
                  </div>

                  {elem.featureddescription ? (
                    <div className={styles.featuresDescription}>
                      <PrismicRichText field={elem.featureddescription} />
                    </div>
                  ) : null}
                </div>
              </Link>
            ))}
          </div>
        </section>
      )}
    </>
  );
};

export default forwardRef(InternalHero);
