export default function capitalizeString(string: string) {

  if (typeof string !== 'string') {
    console.error('Invalid input: Input must be a string.')
    return string
  }
  const words = string.split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(' ');
}
