import Link from 'next/link';

function isRelativeUrl(url) {
    return !(/^[a-zA-Z]+:\/\//.test(url)) // Does not start with a protocol
        && !(/^\/\//.test(url)) // Does not start with protocol-relative URL
        && (/^\//.test(url) || /^\./.test(url) || /^#/.test(url) || /^\?/.test(url));
}

function convertToRelativePath(url) {
    // check if the url is part of fermataenergy.com
    const currentDomain = 'fermataenergy.com';

    const parsedUrl = new URL(url);

    // Remove 'www.' if present and compare with the target domain
    const hostnameWithoutWww = parsedUrl.hostname.replace('www.', '');

    if (hostnameWithoutWww === currentDomain) {
        // Return the pathname, which will be a relative path
        return parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
    }

    // If the URL is external, return the original URL
    return url;
}

// Custom link resolver
export const customLink = ({type, text, node}) => {
    if (type === 'hyperlink') {
        let url = node.data.url;

        // Convert to relative url to take advantage of next/link optimizations
        url = isRelativeUrl(url)  ? url : convertToRelativePath(url);

        if (!isRelativeUrl(url)) {
            // External link
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">
                    {text}
                </a>
            );
        } else {
            // Internal link
            return (
                <Link href={url}>
                    {text}
                </Link>
            );
        }
    }
    // Return other types of content as is
    return null;
};