import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';

const BackgroundPlaceHolder = ({ backgroundImage, styles }) => {
  return (
      <Image
        className={styles.videoPlaceholder}
        src={"/video-bg-placeholder.png"}
        alt={'internal hero'}
        fill
        style={{
          objectFit: 'cover',
        }}
        priority={true}
      />
  );
};

const VideoBackground = ({ styles, videoUrl, placeholderImage }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;

    function handleVideoPlaying() {
      setIsVideoPlaying(true);
    }

    function attachVideoEventListeners() {
      try {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        const videoElement = iframeDocument.querySelector('video');

        if (videoElement) {
          videoElement.addEventListener('playing', handleVideoPlaying);
        }
      } catch (error) {
        console.error('Could not attach video events:', error);
      }
    }

    function onIframeLoad() {
      attachVideoEventListeners();
    }

    if (iframe) {
      iframe.addEventListener('load', onIframeLoad);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener('load', onIframeLoad);
      }
    };
  }, []);


  return (
    <div className={styles.videoContainer}>
      {!isVideoPlaying && (
        <BackgroundPlaceHolder
          backgroundImage={placeholderImage}
          styles={styles}
        />
      )}
      <iframe
        ref={iframeRef}
        className={styles.videoFrame}
        src={videoUrl}
        allow="autoplay; fullscreen; picture-in-picture"
      ></iframe>
    </div>
  );
};

export default VideoBackground;