import React, { forwardRef, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';
import Image from 'next/image';
import styles from '../../styles/slices/StaticTwoColumn.module.scss';
import Link from 'next/link';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

const TwoColumnPanel = ({ slice }, ref) => {
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);

  return (
    <section
      className={classnames(
        styles.section,
        slice.primary.backgroundcolor === 'orange'
          ? styles.bg_orange
          : slice.primary.backgroundcolor === 'white'
          ? styles.bg_white
          : slice.primary.backgroundcolor === 'gray'
          ? styles.bg_gray
          : styles.bg_black,
        slice.primary.textposition === 'right' ? styles.text_right : null,
        slice.primary.texttheme === 'dark' &&
          slice.primary.backgroundcolor !== 'black'
          ? styles.dark_theme
          : styles.lightTheme,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div className={styles.content} ref={wrapperRef}>
        <div className={styles.subtitle}>
          {slice.primary.subtitle ? (
            <PrismicRichText field={slice.primary.subtitle} />
          ) : null}
        </div>
        <span className={styles.title}>
          {slice.primary.title ? (
            <PrismicRichText field={slice.primary.title} />
          ) : null}
        </span>
        <div className={styles.description}>
          {slice.primary.description ? (
            <PrismicRichText field={slice.primary.description} />
          ) : null}
        </div>
        {slice.primary.linkcta.url ? (
          <Link
            href={slice.primary.linkcta.url}
            target={slice.primary.linkcta.target}
          >
            <button
              name="panel link"
              className={classnames(
                styles.btn,
                ['white', 'gray'].includes(slice.primary.backgroundcolor)
                  ? styles.primary_red
                  : styles.secondary_white
              )}
            >
              <span>{slice.primary.linktext}</span>
            </button>
          </Link>
        ) : null}
      </div>
      <div className={styles.image_container}>
        <Image
          src={slice.primary.image.url}
          alt={slice.primary.image.alt || 'column'}
          width={slice.primary.image.dimensions.width}
          height={slice.primary.image.dimensions.height}
          sizes="(max-width: 768px) 100vw, 28.889vw"
        />
      </div>
    </section>
  );
};

export default forwardRef(TwoColumnPanel);
