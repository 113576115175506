import styles from '../../styles/components/ArticleCard.module.scss';
import React, { useContext, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import Link from 'next/link';
import Image from 'next/image';
import { MobileContext } from '../../context/mobileContext';
import classnames from 'classnames';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import arrowRight from '../../public/arrow-right.svg';

export default function ArticleCard({ data }) {
  const isMobile = useContext(MobileContext);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  if (data && !data.layout) {
    data.layout = 'none';
  }
  const bodyWrapper = data ? (
    <div
      className={classnames(
        styles.article_body_wrapper,
        data.layout === 'expanded' ? styles.customerStoriesWrapper : null
      )}
    >
      {data.layout === 'expanded' ? (
        <div className={styles.customerStoriesCard}>
          <span className={styles.cardTitle}>
            {data.data.title ? (
              <PrismicRichText field={data.data.title} />
            ) : null}
          </span>
          <span className={styles.cardDescription}>
            {data.data.description ? (
              <PrismicRichText field={data.data.description} />
            ) : null}
          </span>
          {data.data.articlepageurl.url && (
            <Link
              href={data.data.articlepageurl.url}
              target={data.data.articlepageurl.target}
            >
              <button
                className={classnames(styles.btn, styles.tertiary_black)}
                name="article page"
              >
                {data.data.articlepagelinktext}
                <Image src={arrowRight} alt="arrow" />
              </button>
            </Link>
          )}
        </div>
      ) : (
        <div>
          <p className={styles.date_wrapper}>
            {new Date(data.data.sortingdate).toLocaleDateString('en-GB', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </p>
          <span className={styles.description}>
            <PrismicRichText field={data.data.title} />
          </span>
        </div>
      )}
    </div>
  ) : null;
  const imgWrapper = data ? (
    <div
      className={classnames(
        styles.image_wrapper,
        data.layout === 'expanded' ? styles.customerStoriesImg : null,
        data.layout === 'newsroom' ? styles.newsRoomImg : null
      )}
    >
      <Image
        src={data.data.image.url}
        alt={data.data.image.alt || ''}
        width={data.data.image.dimensions?.width}
        height={data.data.image.dimensions?.height}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        sizes="(max-width: 768px) 255px, 16.667vw"
      />
    </div>
  ) : null;
  if (data) {
    return (
      <div
        className={classnames(
          styles.articleCardWrapper,
          data.layout === 'expanded' ? styles.scrollSnapWrapper : null,
          onScreen?.isIntersecting ? styles.visible : ''
        )}
        ref={wrapperRef}
      >
        {/* {data.data.categorypageurl.url && (
          <Link
            href={{
              pathname: data.data.categorypageurl.url,
              query: { category: data.data.category },
            }}
            target={data.data.categorypageurl.target}
            className={styles.category_position}
          >
            {data.data.category}
          </Link>
        )} */}
        {data.layout === 'expanded' ? (
          imgWrapper
        ) : (
          <>
            {data.data.articlepageurl.url && (
              <Link
                href={data.data.articlepageurl.url}
                passHref
                target={data.data.articlepageurl.target}
              >
                {imgWrapper}
              </Link>
            )}
          </>
        )}
        {data.layout === 'expanded' ? (
          bodyWrapper
        ) : (
          <>
            {data.data.articlepageurl.url && (
              <Link
                href={data.data.articlepageurl.url}
                passHref
                target={data.data.articlepageurl.target}
              >
                {bodyWrapper}
              </Link>
            )}
          </>
        )}
        {!isMobile && data.layout !== 'expanded' && (
          <>
            {data.data.articlepageurl.url && (
              <Link
                href={data.data.articlepageurl.url}
                target={data.data.articlepageurl.target}
                className={styles.bottom_link}
              >
                <Image src={arrowRight} alt="arrow" />
              </Link>
            )}
          </>
        )}
      </div>
    );
  }
}
