import React, { useState, useRef, useEffect } from 'react';
import { PrismicRichText } from '@prismicio/react';
import Image from 'next/image';
import styles from '../../styles/components/ProfileCategory.module.scss';
import { SliceZone } from '@prismicio/react';
import { Profile } from '../../slices';
import closeIcon from '../../public/close.svg';
import classnames from 'classnames';

export default function ProfileCategory({ category }) {
  const bioRef = useRef(null);
  const fourCols = category.data.slices.length === 4;
  const [activeBio, setActiveBio] = useState(null);
  const onToggleFn = (slice) => {
    setActiveBio(slice);
  };

  useEffect(() => {
    if (activeBio) {
      bioRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [activeBio]);

  return (
    <div className={styles.profileCategoryWrapper}>
      <div
        className={classnames(
          styles.sliceWrapper,
          fourCols ? styles.fourCols : ''
        )}
      >
        <SliceZone
          slices={category.data.slices}
          components={{
            profile: (props) => {
              return (
                <Profile
                  slice={props.slice}
                  onToggleFn={onToggleFn}
                  fourCols={fourCols}
                  {...props}
                />
              );
            },
          }}
        />
      </div>
      {activeBio && (
        <div ref={bioRef} className={styles.container_detail}>
          <Image
            className={styles.close_trigger}
            src={closeIcon}
            alt="close icon"
            onClick={() => onToggleFn(null)}
          />
          <div className={styles.box_header}>
            {activeBio.primary.photo.url && (
              <span>
                <Image
                  src={activeBio.primary.photo.url}
                  alt={activeBio.primary.photo.alt || 'bio'}
                  width={activeBio.primary.photo.dimensions.width}
                  height={activeBio.primary.photo.dimensions.height}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </span>
            )}
            <div className={styles.right_area}>
              <p className={styles.jobtitle}>{activeBio.primary.jobtitle}</p>
              <p className={styles.name}>{activeBio.primary.name}</p>
            </div>
          </div>
          <div className={styles.box_content}>
            <PrismicRichText field={activeBio.primary.bio} />
          </div>
        </div>
      )}
    </div>
  );
}
