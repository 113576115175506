import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { useRouter } from 'next/router';
import CaseStudyCard from '../../components/caseStudyCard/caseStudyCard';
import Filters from '../../components/filters/filters';
import classnames from 'classnames';
import styles from '../../styles/slices/CaseStudyListing.module.scss';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

const CaseStudyListing = (
  { slice, cards, query, caseStudies, hideFilter = false },
  ref
) => {
  const router = useRouter();
  const [filters, setFilters] = useState([]);
  const [activeFilter, setActiveFilter] = useState(query.filter ?? 'view all');
  const [activeIndex, setActiveIndex] = useState(slice.primary.itemstodisplay);
  const [filteredCards, setFilteredCards] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);

  const sortCards = () => {
    const sortCards = structuredClone(cards);
    sortCards.map((item) => {
      if (!item.data.sortingdate) {
        item.data.sortingdate = new Date(item.last_publication_date);
      } else {
        item.data.sortingdate = new Date(item.data.sortingdate);
      }
    });
    const sortedCards = sortCards.sort(function (a, b) {
      return b.data.sortingdate - a.data.sortingdate;
    });

    return sortedCards
  }

  const updateFilter = (filter) => {

    // Use window.history.replaceState to update the URL without reloading the page
    window.history.replaceState(
      { ...window.history.state, as: '/knowledge-hub', url: '/knowledge-hub' },
      '',
      `?filter=${filter}`
    );
    setActiveIndex(slice.primary.itemstodisplay);
    setActiveFilter(filter);

    if (filter.toLowerCase() === 'faq') {
      // Redirect to FAQ page
      router.push('/faq');
      setRedirect(true);
      return;
    }

    const sortedCards = sortCards();
    if (filter === 'view all') {
      setFilteredCards(sortedCards);
    } else {
      const tempCards = sortedCards.filter(
        (item) => item.data.category === filter
      );
      setFilteredCards(tempCards);
    }
  };

  const findNumberOfCardsByCategory = (category) => {
    if (category === 'view all') {
      return cards.length;
    } else {
      return filteredCards.length;
    }
  };

  useEffect(() => {
    const sortedCards = sortCards();
    if (query.filter === 'view all' || !query.filter) {
      setFilteredCards(sortedCards);
    } else {
      const tempCards = sortedCards.filter(
        (item) => item.data.category === query.filter
      );
      setFilteredCards(tempCards);
    }
    const filterTempArray = [{ value: 'view all', label: 'view all' }];

    const uniqueCategories = Array.from(new Set(sortedCards.map(card => card.data.category)));
    const categoryFilters = uniqueCategories.map(category => ({
      value: category,
      label: category
    }));
    const filterArray = filterTempArray.concat(categoryFilters);
    setFilters([...filterArray, { value: 'faq', label: 'FAQ' }]);
  }, []);

  /* useEffect(() => {
    if (query.category) {
      const tempCategory = filters.find(
        (element) => element.value === decodeURIComponent(query.category)
      );
      if (tempCategory) {
        updateFilter(tempCategory.value);
      }
    }
  }, [query.category, filters]); */

  useEffect(() => {
    if (query.tag) {
      setActiveIndex(slice.primary.itemstodisplay);
      setActiveFilter('view all');
      const tempTags = caseStudies.results.filter((element) =>
        element.tags.includes(decodeURIComponent(query.tag))
      );
      const sortCards = structuredClone(cards);
      const tempCards = [];
      tempTags.map((item) => {
        const tempCard = sortCards.find(
          (element) => element.id === item.data.cardlink.id
        );
        tempCards.push(tempCard);
      });
      tempCards.map((item) => {
        if (!item.data.sortingdate) {
          item.data.sortingdate = new Date(item.last_publication_date);
        } else {
          item.data.sortingdate = new Date(item.data.sortingdate);
        }
      });
      const sortedCards = tempCards.sort(function (a, b) {
        return b.data.sortingdate - a.data.sortingdate;
      });
      setFilteredCards(sortedCards);
    }
  }, [query.tag, caseStudies]);

  return (
    <section
      className={classnames(
        styles.caseStudyListingWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      ref={ref}
    >
      {query.tag && (
        <p className={styles.tagLine}>
          {filteredCards.length} results found for tag "{query.tag}"
        </p>
      )}
      {!query.tag && !hideFilter && (
        <Filters
          filters={filters}
          updateFn={updateFilter}
          activeFilter={activeFilter}
        ></Filters>
      )}
      {redirect ? (
        <div className={styles.redirectWrapper}>
          <div className={styles.faqRedirect}>
            <h1>Redirecting to FAQ</h1>
            <div>
              <div className={styles.loading} />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.articleWrapper} ref={wrapperRef}>
            {filteredCards.map((item, index) => {
              return (
                (activeFilter === 'view all' ||
                  activeFilter === item.data.category) && (
                  <div
                    className={classnames(
                      styles.article,
                      index < activeIndex ? styles.active : ''
                    )}
                    key={index}
                  >
                    <CaseStudyCard data={item} />
                  </div>
                )
              );
            })}
          </div>
          <div className={styles.btnWrapper}>
            <button
              name="show more"
              onClick={() =>
                setActiveIndex(activeIndex + slice.primary.itemstodisplay)
              }
              className={classnames(
                styles.btn,
                styles.primary_red,
                activeIndex < findNumberOfCardsByCategory(activeFilter)
                  ? styles.visible
                  : '',
                query.tag && activeIndex < findNumberOfCardsByCategory('tag')
                  ? styles.visibleTagOverride
                  : '',
                query.tag && activeIndex >= findNumberOfCardsByCategory('tag')
                  ? styles.invisibleTagOverride
                  : ''
              )}
            >
              {slice.primary.loadmorebuttontext}
            </button>
          </div>
        </>
      )}
    </section>
  );
};

export default forwardRef(CaseStudyListing);
