import React, { useState } from 'react';
import classnames from 'classnames';
import { PrismicRichText } from '@prismicio/react';
import styles from '../../styles/slices/FooterSocialMenu.module.scss';
import Link from 'next/link';
import Image from 'next/image';

const FooterSocial = ({ slice }) => {
  const [over, setOver] = useState(false);
  return (
    <section>
      <div className={styles.social_menu}>
        <div className={styles.social_title}>
          {slice.primary.title ? (
            <PrismicRichText field={slice.primary.title} />
          ) : null}
        </div>
        <ul className={styles.social_list}>
          {slice.items.map((item, index) => (
            <li
              key={index}
              onMouseOver={() => setOver(true)}
              onMouseOut={() => setOver(false)}
            >
              <Link
                href={item.sociallink.url}
                passHref
                target={item.sociallink.target}
              >
                <Image
                  src={over ? item.socialiconhover.url : item.socialicon.url}
                  alt={item.socialicon.alt || 'social icon'}
                  width={item.socialicon.dimensions.width}
                  height={item.socialicon.dimensions.height}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default FooterSocial;
