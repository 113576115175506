import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  forwardRef,
} from 'react';
import { PrismicRichText } from '@prismicio/react';
import ArticleCard from '../../components/articleCard/articleCard';
import styles from '../../styles/slices/CustomerStories.module.scss';
import Link from 'next/link';
import classnames from 'classnames';
import { MobileContext } from '../../context/mobileContext';
import Image from 'next/image';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import vectorImage from '../../public/Vector.svg';

const CustomerStories = ({ slice, cards }, ref) => {
  const [filteredCards, setFilteredCards] = useState([,]);
  const isMobile = useContext(MobileContext);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  useEffect(() => {
    const tempFilteredCards = [,];
    const sortCards = structuredClone(cards);
    sortCards.map((item) => {
      if (!item.data.sortingdate) {
        item.data.sortingdate = new Date(item.last_publication_date);
      } else {
        item.data.sortingdate = new Date(item.data.sortingdate);
      }
    });
    const sortedCards = sortCards.sort(function (a, b) {
      return b.data.sortingdate - a.data.sortingdate;
    });
    sortedCards.map((item) => {
      if (slice.primary.firstcard.id === item.id) {
        tempFilteredCards[0] = item;
        tempFilteredCards[0].layout = 'expanded';
      }
      if (slice.primary.secondcard.id === item.id) {
        tempFilteredCards[1] = item;
        tempFilteredCards[1].layout = 'expanded';
      }
    });
    setFilteredCards(tempFilteredCards);
  }, [slice, cards]);

  return (
    <section
      className={classnames(
        styles.customerStoriesWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div className={styles.leftSideContent} ref={wrapperRef}>
        <div className={styles.subtitle}>
          <PrismicRichText field={slice.primary.subtitle} />
        </div>
        <div className={styles.title}>
          <PrismicRichText field={slice.primary.title} />
        </div>
        {!isMobile && (
          <Link href={slice.primary.linkcta.url}>
            <button
              className={classnames(styles.btn, styles.secondary_white)}
              name="view all"
            >
              {slice.primary.linktext} <Image src={vectorImage} alt="vector" />
            </button>
          </Link>
        )}
      </div>
      <div className={styles.articleCardWrapper}>
        {filteredCards.map((item, index) => {
          return <ArticleCard key={index} data={item} />;
        })}
      </div>
      {isMobile && (
        <div className={styles.btnWrapper}>
          <Link href={slice.primary.linkcta.url}>
            <button
              className={classnames(styles.btn, styles.secondary_white)}
              name="view all"
            >
              {slice.primary.linktext} <Image src={vectorImage} alt="vector" />
            </button>
          </Link>
        </div>
      )}
    </section>
  );
};

export default forwardRef(CustomerStories);
