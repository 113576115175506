import React, { forwardRef, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import styles from '../../styles/slices/Callout.module.scss';
import Link from 'next/link';
import classnames from 'classnames';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import Image from 'next/image';

const Callout = ({ slice }, ref) => {
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  return (
    <section
      className={classnames(
        styles.callout_section,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div
        className={classnames(
          styles.callout_content,
          slice.primary.backgroundcolor &&
            slice.primary.background.url === 'violet'
            ? styles.callout_violet
            : slice.primary.backgroundcolor &&
              slice.primary.background.url === 'red'
            ? styles.callout_red
            : slice.primary.backgroundcolor &&
              slice.primary.background.url === 'white'
            ? styles.callout_white
            : slice.primary.backgroundcolor &&
              slice.primary.background.url === 'light-grey'
            ? styles.callout_light_grey
            : slice.primary.backgroundcolor &&
              slice.primary.background.url === 'dark-grey'
            ? styles.callout_dark_grey
            : styles.callout_orange
        )}
        ref={wrapperRef}
      >
        {slice.primary.background.url && (
          <div className={styles.background}>
            <Image
              src={slice.primary.background.url}
              alt={slice.primary.background.alt || 'callout'}
              width={slice.primary.background.dimensions.width}
              height={slice.primary.background.dimensions.height}
              sizes="(max-width: 768px) 100vw, 71.667vw"
            />
          </div>
        )}
        <div className={styles.callout_text}>
          {slice.primary.title ? (
            <PrismicRichText field={slice.primary.title} />
          ) : null}
        </div>
        <div className={styles.calloutbtn}>
          {slice.primary.linkcta ? (
            <Link
              href={slice.primary.linkcta.url}
              target={slice.primary.linkcta.target}
            >
              <button
                name="callout link"
                className={classnames(
                  styles.btn,
                  slice.primary.backgroundcolor === 'white' ||
                    slice.primary.backgroundcolor === 'light-grey'
                    ? styles.primary_red
                    : styles.secondary_white
                )}
              >
                <span>{slice.primary.linktext}</span>
              </button>
            </Link>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default forwardRef(Callout);
