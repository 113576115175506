export default function imgixLoader({ src, width }) {
  try {
    const url = new URL(src);
    const params = url.searchParams;
    params.set('auto', params.getAll('auto').join(',') || 'format');
    params.set('fit', params.get('fit') || 'max');
    params.set('w', width.toString());
    return url.href;
  } catch {
    return src;
  }
}
