import React, { forwardRef, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import styles from '../../styles/slices/Quote.module.scss';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import classnames from 'classnames';

const Quote = ({ slice }, ref) => {
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  return (
    <section
      className={classnames(
        styles.quoteWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div className={styles.textWrapper} ref={wrapperRef}>
        <PrismicRichText field={slice.primary.quote} />
        <p className={styles.author}>{slice.primary.author}</p>
      </div>
    </section>
  );
};

export default forwardRef(Quote);
