import React, { useState, useEffect, useCallback } from 'react';
import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';
import styles from '../../styles/slices/HomeHero.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import { forwardRef } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Vimeo from '@u-wave/react-vimeo';
import playIcon from '../../public/play_testimonials.svg';

const HomeHero = ({ slice }, ref) => {
  const [playVimeo, setPlayVimeo] = useState(false);
  const [open, setOpen] = useState(false);

  function isBrowser() {
    return typeof window !== 'undefined';
  }

  const getDimensions = useCallback(() => {
    if (!isBrowser()) return { width: 0, height: 0 };

    const viewportWidth = window.innerWidth;
    const width = viewportWidth >= 768 ? viewportWidth * 0.75 : viewportWidth;

    return {
      width,
      height: window.innerHeight,
    };
  }, []);

  useEffect(() => {
    if (!isBrowser()) return;

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getDimensions]);

  const [dimensions, setDimensions] = useState(getDimensions());

  const PauseVideos = () => {
    setPlayVimeo(true);
  };

  return (
    <section
      className={classnames(
        styles.heroSection,
        slice.primary.imagestyle === 'full-width'
          ? styles.fullWidth
          : styles.halfWidth,
        slice.primary.textposition === 'right' ? styles.reverse : null,
        slice.primary.texttheme === 'dark'
          ? styles.darkTheme
          : styles.lightTheme
      )}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div className={styles.image}>
        <Image
          src={slice.primary.background.url}
          alt={slice.primary.background.alt || 'home hero'}
          width={slice.primary.background.dimensions.width}
          height={slice.primary.background.dimensions.height}
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
          }}
          priority={true}
          sizes="(max-width: 768px) 100vw, 50vw"
        />

        {slice.primary?.vimeovideo?.url ? (
          <div className={styles.videoWrapper}>
            <div
              className={styles.imagePlay}
              onClick={() => {
                setOpen(true);
                setPlayVimeo(true);
              }}
            >
              <div className={styles.imagePlayWrapper}>
                <Image src={playIcon} alt="Play" />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {slice.primary?.vimeovideo?.url ? (
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          slides={[
            {
              type: 'custom-slide',
            },
          ]}
          carousel={{
            finite: true,
          }}
          styles={{ root: { '--yarl__color_backdrop': 'rgba(0, 0, 0, .8)' } }}
          controller={{ closeOnBackdropClick: true }}
          render={{
            slide: ({ slide }) =>
              slide.type === 'custom-slide' ? (
                <>
                  <Vimeo
                    video={slice.primary?.vimeovideo?.url}
                    paused={!playVimeo}
                    onPause={PauseVideos}
                    autoplay={playVimeo}
                    width={dimensions.width}
                    height={dimensions.height}
                  />
                </>
              ) : undefined,
            buttonPrev: () => null,
            buttonNext: () => null,
          }}
        />
      ) : null}
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <span className={styles.title}>
            <div className={styles.subtitle}>
              {slice.primary.subtitle ? (
                <PrismicRichText field={slice.primary.subtitle} />
              ) : null}
            </div>
            {slice.primary.title ? (
              <PrismicRichText field={slice.primary.title} />
            ) : null}
            {slice.primary.description ? (
              <PrismicRichText field={slice.primary.description} />
            ) : null}
            {slice.primary.buttoncta && slice.primary.buttoncta.url ? (
              <Link
                href={slice.primary.buttoncta.url}
                target={slice.primary.buttoncta.target}
              >
                <button
                  className={classnames(
                    styles.btn,
                    slice.primary.texttheme === 'dark'
                      ? styles.primary_red
                      : styles.secondary_white
                  )}
                  name="hero link"
                >
                  {slice.primary.buttontext}{' '}
                </button>
              </Link>
            ) : null}
          </span>
        </div>
      </div>
      <div
        className={classnames(
          styles.featuresWrapper,
          slice.primary.showfeaturedhighlights === false
            ? styles.featuresHide
            : null
        )}
      >
        {slice.items.map((elem, index) => (
          <Link key={index} href={`${elem.featuredlinkcta.url}`} passHref>
            <div className={styles.featuresContainer}>
              <div className={styles.featuresContent}>
                {elem.featuredlogo.url ? (
                  <div className={styles.featureIcon}>
                    <Image
                      src={elem.featuredlogo.url}
                      alt={elem.featuredlogo.alt || 'featured logo'}
                      width={elem.featuredlogo.dimensions.width}
                      height={elem.featuredlogo.dimensions.height}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                      }}
                    />
                  </div>
                ) : null}
                {elem.featuredsubtitle && elem.featuredtitle ? (
                  <div className={styles.featuresTitle}>
                    <PrismicRichText field={elem.featuredsubtitle} />
                    <PrismicRichText field={elem.featuredtitle} />
                  </div>
                ) : null}
              </div>

              {elem.featureddescription ? (
                <div className={styles.featuresDescription}>
                  <PrismicRichText field={elem.featureddescription} />
                </div>
              ) : null}
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default forwardRef(HomeHero);
