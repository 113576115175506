import React, { forwardRef, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import styles from '../../styles/slices/StatsCards.module.scss';
import MixedContentCard from '../../components/mixedContentCard/mixedContentCard';
import classnames from 'classnames';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

const StatsCards = ({ slice, cards }, ref) => {
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  return (
    <section
      className={classnames(
        styles.statsCardsWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div ref={wrapperRef}>
        {slice.primary?.title[0]?.text && (
          <div className={styles.stats_title_wrapper}>
            <PrismicRichText field={slice.primary.title}></PrismicRichText>
          </div>
        )}

        <div
          className={classnames(
            styles.cards_container,
            styles.big_container_wrapper
          )}
        >
          {slice.items.map((item, index) => {
            const card = cards.find((element) => element.id === item.card.id);
            return (
              <MixedContentCard
                key={index}
                card={card}
                layout={slice.items.length > 3 ? 'four_column' : ''}
              ></MixedContentCard>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default forwardRef(StatsCards);
