import React, { forwardRef, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import Image from 'next/image';
import styles from '../../styles/slices/FourColumnSteps.module.scss';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import classnames from 'classnames';

const FourColumnSteps = ({ slice }, ref) => {
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  return (
    <section
      className={classnames(
        styles.fourColumnStepsWrapper,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div className={styles.textWrapper} ref={wrapperRef}>
        {slice.primary.subtitle ? (
          <span className={styles.subtitle}>
            <PrismicRichText field={slice.primary.subtitle} />
          </span>
        ) : null}
        {slice.primary.title ? (
          <span className={styles.title}>
            <PrismicRichText field={slice.primary.title} />
          </span>
        ) : null}
        {slice.primary.description ? (
          <span className={styles.description}>
            <PrismicRichText field={slice.primary.description} />
          </span>
        ) : null}
      </div>
      <div className={styles.cardWrapper}>
        {slice.items.map((item, index) => {
          return (
            <div className={styles.card} key={index}>
              <p className={styles.category}>{item.step}</p>
              <Image
                src={item.image.url}
                alt={item.image.alt || 'step'}
                width={item.image.dimensions.width}
                height={item.image.dimensions.height}
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                }}
                sizes="(max-width: 768px) 100vw, 18vw"
              />
              <div className={styles.cardDescription}>
                {item.title ? (
                  <span className={styles.cardTitle}>
                    <PrismicRichText field={item.title} />
                  </span>
                ) : null}
                {item.description ? (
                  <span className={styles.cardText}>
                    <PrismicRichText field={item.description} />
                  </span>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default forwardRef(FourColumnSteps);
